import { Pipe, PipeTransform } from "@angular/core";

/**
 * Allows forcefully cast a value into a type. The type has to be specified by passing example of the desired type.
 * If none is passed, the result will be any.
 * Note that no validation is done inside - this will happily let you cast the string "bob" into a Date.
 *
 * @example
 *  <div *ngIf="context | async | lgTypeCast: _tooltipType as c">...</div>
 * ..
 * _tooltipType!: LgBubbleChartTooltipContext;
 *
 */
@Pipe({
    standalone: true,
    name: "lgTypeCast",
    pure: true
})
export class LgTypeCastPipe implements PipeTransform {
    transform(value: any): any;
    transform<T>(value: any, _example: T): T;
    transform<T>(value: any, _example?: T): T {
        return value;
    }
}
