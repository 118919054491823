import { ILgFormatter, LgConsole, NumberFormatterFactory } from "@logex/framework/core";
import { LgTooltipService, TooltipApi, TooltipPosition } from "@logex/framework/ui-core";
import { Directive, inject, Input, OnInit } from "@angular/core";
import _ from "lodash";
import { ICopyColumnInfo } from "./copy-paste.types";

@Directive()
export abstract class LgCopyBase<TCopyColumnInfo extends ICopyColumnInfo> implements OnInit {
    protected _console = inject(LgConsole).withSource("Logex.Directives.PasteButtonController");
    protected _tooltipService = inject(LgTooltipService);
    protected _numberFormatterFactory = inject(NumberFormatterFactory);

    showWait: boolean;

    protected _columns: TCopyColumnInfo[];
    protected _numberFormatter: ILgFormatter<number>;
    protected _tooltip: TooltipApi;

    // ----------------------------------------------------------------------------------
    @Input({ required: true }) onGetData!: () => any[];

    @Input() notificationPosition?: TooltipPosition;

    @Input() set columnsDefinitions(val: TCopyColumnInfo[]) {
        this._columns = val;
    }

    get columnsDefinitions(): TCopyColumnInfo[] {
        return this._columns;
    }

    @Input() iconClass?: string;

    // ----------------------------------------------------------------------------------
    ngOnInit(): void {
        this.showWait = false;

        this._tooltip = this._tooltipService.create({ position: this.notificationPosition });
        this._numberFormatter = this._numberFormatterFactory.create({
            decimals: 20,
            forceFormat: false
        });
    }

    protected copyDataDo(data: any[]): void {
        if (!data) {
            return;
        }

        let aux: HTMLElement;
        const onCopy = (e: ClipboardEvent): void => {
            try {
                const convertedData = this._getConvertData(data);
                const clipboardData = e.clipboardData;
                clipboardData.setData("text/plain", convertedData);

                // if ( this.hideTimeout ) {
                //     this.$timeout.cancel( this.hideTimeout );
                // }

                this._tooltip.hideShow();

                this.showWait = true;
                setTimeout(() => (this.showWait = false), 500);

                // this.hideTimeout = window.setTimeout( () => this.tooltip.hide(), 1500 );

                e.preventDefault();
            } finally {
                if (aux) document.body.removeChild(aux);
                document.removeEventListener("copy", onCopy);
            }
        };

        document.addEventListener("copy", onCopy);
        document.execCommand("copy");
    }

    private _getConvertData(data: any[]): string {
        return _.union(
            [_.map(this._columns, y => y.name).join("\t")],
            _.map(data, x =>
                _.map(this._columns, y => {
                    const val = x[y.field];
                    if (_.isNumber(val)) {
                        return this._numberFormatter.formatForEditing(val);
                    } else if (val != null) {
                        let escaped = val.replace(/\r\n/g, "\n");
                        escaped = escaped.replace(/"/g, '""');
                        return `"${escaped}"`;
                    } else {
                        return val;
                    }
                }).join("\t")
            )
        ).join("\r\n");
    }
}
