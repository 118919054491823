import _ from "lodash";
import { InjectionToken } from "@angular/core";

export const LG_COL_DEFINITION_TYPE_CONFIGURATION = new InjectionToken<TableTypeConfiguration>(
    "lgColDefinitionTypeConfiguration"
);

export interface ColumnTypeConfiguration {
    paddingLeft?: number;
    paddingRight?: number;
    paddingLeftOfFirst?: number;
    paddingRightOfLast?: number;
    defaultWidth?: number;
    classNames: string[];
    isHidden?: boolean;
}

export interface TableTypeConfiguration {
    name: string;
    defaultRowWidth?: number;
    defaultPadding?: number;
    defaultEndsPadding?: number;
    defaultColumnClass?: string;
    inherit?: string;

    columns: _.Dictionary<ColumnTypeConfiguration>;
}
