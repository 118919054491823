import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    inject
} from "@angular/core";
import { copyTextToClipboard } from "../helpers";

/*
  Possible extensions:
  - allow other than text data
  - allow providing animation (through class, or just (activate) output?)
*/

@Directive({
    standalone: true,
    selector: "[lgCopyHandler]"
})
/**
 * Implement possibility to handle copy event on any element (focusable, or containing focusable children). When the
 * event is triggered, the given callback must provide a text which should be put into clipboard.
 * Because the general copy event won't be triggered on IE, we explicitly handle ctrl+c there (and only this
 * combination)
 */
export class LgCopyHandlerDirective implements OnChanges, OnDestroy {
    private _elementRef = inject(ElementRef<Node>);

    /**
     * Callback for providing the value to be copied to the clipboard.
     */
    @Input("lgCopyHandler") getValue: null | undefined | (() => string | null);

    private _attached = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.getValue) {
            this._bindHandlers();
        }
    }

    ngOnDestroy(): void {
        this._detachHandlers();
    }

    private readonly _onCopyBound = (event: ClipboardEvent): void => this._onCopy(event);
    private readonly _onKeyUpBound = (event: KeyboardEvent): void => this._onKeyUp(event);

    private _bindHandlers(): void {
        const active = this.getValue != null;
        if (active) {
            this._attachHandlers();
        } else {
            this._detachHandlers();
        }
    }

    private _attachHandlers(): void {
        if (this._attached) return;
        document.addEventListener("copy", this._onCopyBound);
        this._attached = true;
    }

    private _detachHandlers(): void {
        if (!this._attached) return;
        document.removeEventListener("copy", this._onCopyBound);
        this._attached = false;
    }

    // Note: global binding on document level, because binding on non-editable element doesn't seem to
    // work reliably in chrome (only element selected by mouse click reacts, not elements to which we tabbed)
    private _onCopy(event: ClipboardEvent): void {
        if (event.clipboardData && this._isFocusInside()) {
            const text = this.getValue?.();
            if (text) {
                // Note: this path will never trigger on IE, so we can use text/plain
                event.clipboardData.setData("text/plain", text);
                event.preventDefault();
            }
        }
    }

    private _onKeyUp(event: KeyboardEvent): void {
        if (event.key === "c" && event.ctrlKey) {
            const text = this.getValue?.();
            if (text) {
                copyTextToClipboard(text);
                event.stopPropagation();
                event.preventDefault();
            }
        }
    }

    private _isFocusInside(): boolean {
        let current = document.activeElement;
        while (current) {
            if (current === this._elementRef.nativeElement) return true;
            current = current.parentElement;
        }
        return false;
    }
}
