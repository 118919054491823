import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from "@angular/core";
import { ProcessedNavNode } from "@logex/framework/lg-application";

@Component({
    selector: "lg-sidebar-menu-item",
    templateUrl: "./lg-sidebar-menu-item.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSidebarMenuItemComponent {
    /**
     * Current active URL
     */
    @Input({ required: true })
    currentUrl!: string;

    /**
     * Navigation node to be processed
     */
    @Input({ required: true })
    navNode!: ProcessedNavNode;

    /**
     * Menu level of processed navigation node
     *
     * @default 1
     */
    @Input()
    level?: number = 1;

    /**
     * Navigate to processed navigation node
     * */
    @Output()
    readonly navigate = new EventEmitter<ProcessedNavNode>();

    get cssMenuItem(): { [klass: string]: boolean } {
        return {
            active: this.isActive(),
            disabled: !!this.navNode.disabled,
            "lg-sidebar-menu-item--collapsible": this.hasChildren(),
            "lg-sidebar-menu-item--regular": !this.hasChildren()
        };
    }

    get marginLeftPx(): number {
        return 6 + (this.level - 1) * 12;
    }

    isActive(): boolean {
        const check = (item: ProcessedNavNode): boolean => {
            return (
                item.getHrefNoQuery() === this.currentUrl ||
                item.children?.some(child => check(child))
            );
        };
        return check(this.navNode);
    }

    isExpanded(): boolean {
        return !!this.navNode.data?.expanded;
    }

    hasChildren(item?: ProcessedNavNode): boolean {
        return !!(item || this.navNode).children?.length;
    }

    hasToggle(): boolean {
        return !this.navNode.disabled && this.hasChildren();
    }

    onContextMenu($event: MouseEvent): void {
        if (this.navNode.disabled) $event.preventDefault();
    }

    onClick($event: Event): void {
        $event.stopPropagation();
        $event.preventDefault();
        if (this.navNode.disabled) return;
        const itemToNavigate = (item: ProcessedNavNode): ProcessedNavNode | null => {
            if (item.disabled) return null;
            item.data.expanded = true;
            return this.hasChildren(item)
                ? item.children.find(child => !!itemToNavigate(child))
                : item;
        };
        const navigateTo = itemToNavigate(this.navNode);
        if (navigateTo) {
            this.navigate.emit(navigateTo);
        }
    }

    onToggle(): void {
        this.navNode.data.expanded = !this.navNode.data.expanded;
    }
}
