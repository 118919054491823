import _ from "lodash";
import { inject, Injectable } from "@angular/core";
import { noop, ReplaySubject, Observable } from "rxjs";
import { Connection, hubConnection, Proxy } from "signalr-no-jquery";

import { LgConsole } from "@logex/framework/core";
import { ISubscriptionTicket, IMessageBusService } from "./lg-message-bus.types";

// ----------------------------------------------------------------------------------
//
interface ConnectionExt extends Connection {
    starting?: (callback: Function) => Connection;
    received?: (callback: Function) => Connection;
    error?: (callback: Function) => Connection;
    stateChanged?: (callback: Function) => Connection;
    connectionSlow?: (callback: Function) => Connection;
    reconnecting: (callback: Function) => void;
    reconnected?: (callback: Function) => Connection;
    disconnected: (callback: Function) => void;
}

@Injectable({ providedIn: "root" })
export class LgMessageBusOldService implements IMessageBusService {
    private _console = inject(LgConsole).withSource(
        "Logex.Infrastructure.MessageBus.MessageBusService"
    );

    connectionEstablished$: Observable<boolean>;

    private _connectionIdParamName = "X-SignalR-ConnectionId";
    private _hubName = "application";

    private _connection: ConnectionExt = hubConnection(".") as ConnectionExt;
    private _hubProxy = this._connection.createHubProxy(this._hubName);
    private readonly _connectionEventSubscription: _.Dictionary<Function[]> = {};

    private _initialized: boolean;
    private _connectionEstablished$ = new ReplaySubject<boolean>();

    // ----------------------------------------------------------------------------------
    //
    constructor() {
        this.connectionEstablished$ = this._connectionEstablished$.asObservable();
    }

    // ----------------------------------------------------------------------------------
    //
    getConnectionId(): string {
        if (!this._initialized) {
            this._initialize();
        }

        return this._connection.id;
    }

    // ----------------------------------------------------------------------------------
    //
    on(message: string, cb: Function): ISubscriptionTicket {
        if (!this._initialized) {
            this._initialize();
        }

        this._hubProxy.on(message, cb as (...msg: any[]) => void);
        return new SubscriptionTicket(this._hubProxy, message, cb);
    }

    // ----------------------------------------------------------------------------------
    //
    onConnectionEvent(eventName: string, cb: Function, scope?: any): ISubscriptionTicket {
        if (!this._initialized) {
            this._initialize();
        }

        let subscriptions = this._connectionEventSubscription[eventName];
        if (!subscriptions) {
            subscriptions = [];
            this._connectionEventSubscription[eventName] = subscriptions;
        }

        if (scope) cb = cb.bind(scope);

        subscriptions.push(cb);

        return new ConnectionEventSubscriptionTicket(subscriptions, cb);
    }

    private _initialize(): void {
        this._hubProxy.on("noop", noop); // no-operation as a dummy callback listener. Required for connection to start receiving events

        this._connection
            .start({ logging: true })
            .done(() => {
                document.cookie = `${this._connectionIdParamName}=${this._connection.id}`;
                this._connectionEstablished$.next(true);
            })
            .fail(() => {
                this._connectionEstablished$.next(false);
            });

        this._connection.starting(_.partial(this._onConnectionEvent, "starting").bind(this));
        this._connection.received(_.partial(this._onConnectionEvent, "received").bind(this));
        this._connection.error(_.partial(this._onConnectionEvent, "error").bind(this));
        this._connection.stateChanged(
            _.partial(this._onConnectionEvent, "stateChanged").bind(this)
        );
        this._connection.connectionSlow(
            _.partial(this._onConnectionEvent, "connectionSlow").bind(this)
        );
        this._connection.reconnecting(
            _.partial(this._onConnectionEvent, "reconnecting").bind(this)
        );
        this._connection.reconnected(_.partial(this._onConnectionEvent, "reconnected").bind(this));
        this._connection.disconnected(
            _.partial(this._onConnectionEvent, "disconnected").bind(this)
        );
        this._initialized = true;
    }

    // ----------------------------------------------------------------------------------
    //
    private _onConnectionEvent(eventName: string, ...data: any[]): void {
        this._console.debug("Connection event:", eventName, data);

        const subscriptions = this._connectionEventSubscription[eventName];
        if (subscriptions) {
            _.each(subscriptions, cb => {
                try {
                    cb(data);
                } catch (e) {
                    this._console.error("Callback exception", e);
                }
            });
        }
    }
}

// ----------------------------------------------------------------------------------
//
class SubscriptionTicket implements ISubscriptionTicket {
    private _hubProxy;
    private _message;
    private _callback: Function;

    // ----------------------------------------------------------------------------------
    //
    constructor(hubProxy: Proxy, message: string, callback: Function) {
        this._hubProxy = hubProxy;
        this._message = message;
        this._callback = callback;
    }

    // ----------------------------------------------------------------------------------
    //
    cancel(): void {
        this._off(this._message, this._callback);
    }

    // ----------------------------------------------------------------------------------
    //
    private _off(messageName: string, cb: Function): void {
        this._hubProxy.off(messageName, cb as (...msg: any[]) => void);
    }
}

// ----------------------------------------------------------------------------------
//
class ConnectionEventSubscriptionTicket implements ISubscriptionTicket {
    // ----------------------------------------------------------------------------------
    //
    constructor(private _subscriptions: Function[], private _callback: Function) {}

    // ----------------------------------------------------------------------------------
    //
    cancel(): void {
        _.pull(this._subscriptions, this._callback);
    }
}
