import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { IDialogOptions } from "./lg-dialog.types";
import { LgDialogHolderComponent } from "./lg-dialog-holder.component";
import { IOverlayResultApi } from "../lg-overlay/lg-overlay.service";

export class LgDialogRef<T, R = any> {
    public componentInstance: T;

    public get visible(): boolean {
        return this._visible;
    }

    public get options(): IDialogOptions {
        return this._options;
    }

    public get isTop(): boolean {
        return this._overlay.isTop();
    }

    private _dying = false;
    private readonly _beforeClosed = new Subject<R>();
    private readonly _afterClosed = new Subject<R>();
    private _dialogResult: R;
    private _visible = false;

    constructor(
        readonly id: string,
        private _options: IDialogOptions,
        private _overlay: IOverlayResultApi,
        public _holderInstance: LgDialogHolderComponent
    ) {
        this._holderInstance._requestClose
            .pipe(takeUntil(this._beforeClosed))
            .subscribe(() => this._tryClose());
    }

    public close(dialogResult?: R, immediately?: boolean): void {
        if (this._dying) return;

        if (!this.visible) {
            console.warn(`The dialog ${this.id} is already hidden`);
            return;
        }

        this._dialogResult = dialogResult;
        this._dying = true;

        this._beforeClosed.next(this._dialogResult);
        this._beforeClosed.complete();

        if (this._options.onClose) {
            this._options.onClose(this);
        }

        this._visible = false;

        if (immediately) {
            this._overlay.hide();
            this._afterClosed.next(this._dialogResult);
            this._afterClosed.complete();
        } else {
            this._overlay.overlayRef.detachBackdrop();
            this._holderInstance
                .hide()
                .pipe(take(1))
                .subscribe(() => {
                    this._overlay.hide();
                    this._afterClosed.next(this._dialogResult);
                    this._afterClosed.complete();
                });
        }
    }

    public isTopLayer(): boolean {
        return this.visible && this._overlay.isTop();
    }

    public center(): void {
        this._holderInstance._center(true);
    }

    public maybeCenter(overridePosition = false): void {
        this._holderInstance._maybeCenter(overridePosition);
    }

    public beforeClosed(): Observable<R | undefined> {
        return this._beforeClosed.asObservable();
    }

    public afterClosed(): Observable<R | undefined> {
        return this._afterClosed.asObservable();
    }

    public keydownEvents(): Observable<KeyboardEvent> {
        return this._overlay.overlayRef.keydownEvents().pipe(takeUntil(this._beforeClosed));
    }

    private _tryClose(): void {
        if (this._options.tryClose) {
            if (!this._options.tryClose(this)) return;
        }
        this.close();
    }

    public finalizedOptions(options: IDialogOptions): void {
        this._options = options;
        if (this._options.closeOnEsc) {
            this._overlay.overlayRef
                .keydownEvents()
                .pipe(takeUntil(this._beforeClosed))
                .subscribe((e: KeyboardEvent) => {
                    if (e.key?.toLowerCase() !== "escape") {
                        return;
                    }

                    if (
                        document.activeElement instanceof HTMLInputElement ||
                        document.activeElement instanceof HTMLTextAreaElement
                    ) {
                        document.activeElement.blur();
                    } else {
                        this._tryClose();
                    }
                });
        }
        if (this._options.closeOnOverlayClick) {
            this._overlay.overlayRef
                .backdropClick()
                .pipe(takeUntil(this._beforeClosed))
                .subscribe(() => {
                    this._tryClose();
                });
        }
        this._visible = true;
    }
}
