/**
 * Emulates node.getRootNode() without support for the additional options (shadow DOM handling).
 * Calls native method on modern browser, polyfills on IE11
 */
export function getRootNodeSimple(element: Node): Node {
    if (!element) return element;
    if ("getRootNode" in (element as any)) return element.getRootNode();
    let result = element;
    while (result.parentNode) result = result.parentNode;
    return result;
}
