import _ from "lodash";
import { Observable } from "rxjs";
import { map, filter, share } from "rxjs/operators";

import {
    LgColDefinitionSource,
    LgColDefinitionColumnSource,
    LgColDefinitionLikeSource,
    LgColDefinitionInheritSource
} from "./definition-column-source";
import { TableTypeConfiguration } from "./column-type-configuration";
import {
    IRowDefinition,
    IColumnDefinition,
    IDefinition,
    LgColDefinitionConditional
} from "./internal.types";
import { LgColDefinitionInstantiated } from "./column-definition-instantiated";

export class LgColDefinitionPrepared {
    private _definition: IDefinition;
    private _ifs: LgColDefinitionConditional[];
    private _lastIfValues: boolean[];
    private readonly _conditionsChanged$: Observable<LgColDefinitionPrepared>;
    private _defaultRowWidth: number;

    // ---------------------------------------------------------------------------------------------
    constructor(
        source: LgColDefinitionSource,
        configuration: TableTypeConfiguration,
        tick$: Observable<void>
    ) {
        this._definition = this._processDefinition(source, configuration);
        this._defaultRowWidth = configuration.defaultRowWidth;
        this._finalize();

        this._ifs = _(this._definition)
            .map(r => r.columns)
            .flatten()
            .map(c => c.if)
            .filter()
            .uniq()
            .value();

        this._lastIfValues = _.map(this._ifs, i => i());

        this._conditionsChanged$ = tick$.pipe(
            map(() => {
                const oldValues = this._lastIfValues;
                this._lastIfValues = _.map(this._ifs, i => i());
                return !_.isEqual(oldValues, this._lastIfValues);
            }),
            filter(changed => changed),
            map(() => {
                this._finalize();
                return this;
            }),
            share()
        );
    }

    // ---------------------------------------------------------------------------------------------
    public onChange(): Observable<LgColDefinitionPrepared> {
        return this._conditionsChanged$;
    }

    // ---------------------------------------------------------------------------------------------
    public instantiate(rowWidth?: number | null): LgColDefinitionInstantiated {
        return new LgColDefinitionInstantiated(this._definition, rowWidth || this._defaultRowWidth);
    }

    // ---------------------------------------------------------------------------------------------
    private _finalize(): void {
        _.each(this._definition, row => this._finalizeRowDefinition(row));
    }

    private _finalizeRowDefinition(rowDefinition: IRowDefinition): void {
        for (const col of rowDefinition.columns) {
            col.first = col.last = false;
            if (col.if) {
                // If ngIf predicate evaluates to false, hide the column
                col.isHiddenNow = col.isHidden || !col.if();
            } else {
                col.isHiddenNow = col.isHidden;
            }
        }

        const l = rowDefinition.columns.length;

        // try to skip the hidden columns in the start and in the end
        rowDefinition.firstVisible = 0;
        while (
            rowDefinition.firstVisible < l &&
            rowDefinition.columns[rowDefinition.firstVisible].isHiddenNow
        ) {
            ++rowDefinition.firstVisible;
        }

        rowDefinition.lastVisible = l - 1;
        while (
            rowDefinition.lastVisible > rowDefinition.firstVisible &&
            rowDefinition.columns[rowDefinition.lastVisible].isHiddenNow
        ) {
            --rowDefinition.lastVisible;
        }

        let allocatedWidth = 0;
        for (let i = rowDefinition.firstVisible; i <= rowDefinition.lastVisible; ++i) {
            const column = rowDefinition.columns[i];
            if (column.isHiddenNow) continue;

            allocatedWidth += column.width + column.widthTweak;

            if (i > rowDefinition.firstVisible) {
                allocatedWidth += column.paddingLeft;
            } else {
                allocatedWidth += column.paddingLeftOfFirst;
                column.first = true;
            }

            if (i < rowDefinition.lastVisible) {
                allocatedWidth += column.paddingRight;
            } else {
                allocatedWidth += column.paddingRightOfLast;
                column.last = true;
            }
        }

        rowDefinition.allocatedWidth = allocatedWidth;
        rowDefinition.factorSum = _.sumBy(rowDefinition.flexibleColumns, c =>
            c.isHiddenNow ? 0 : c.factor
        );
    }

    // ---------------------------------------------------------------------------------------------
    private _processDefinition(
        source: LgColDefinitionSource,
        configuration: TableTypeConfiguration
    ): IDefinition {
        const rows: IDefinition = {};
        const rootPadding = source.padding != null ? source.padding : configuration.defaultPadding!;
        const rootColumnClasses =
            source.columnClasses != null
                ? source.columnClasses
                : configuration.defaultColumnClass
                ? [configuration.defaultColumnClass]
                : [];

        for (const rowSource of source.rows) {
            if (!rowSource.id) {
                console.warn("LgColDefinitionSource row is missing id ", rowSource);
                continue;
            }
            const columnClasses =
                rowSource.columnClasses != null ? rowSource.columnClasses : rootColumnClasses;

            const rowDefinition: IRowDefinition = {
                id: rowSource.id,
                columns: [],
                columnMap: {},
                flexibleColumns: [],
                allocatedWidth: 0,
                factorSum: 0,
                firstVisible: 0,
                lastVisible: 0,
                columnClasses
            };

            if (rows[rowDefinition.id]) {
                console.error(
                    "lgColumnDefinition multiple rows with identical id ",
                    rowDefinition.id
                );
                continue;
            }

            rows[rowDefinition.id] = rowDefinition;

            for (const colSource of rowSource.columns) {
                if (colSource.node === "column") {
                    this._processColumn(colSource, rowDefinition, rootPadding, configuration);
                } else {
                    const srcRow = rows[colSource.row];
                    if (!srcRow) {
                        console.error(
                            `gColumnDefinition ${colSource.row} inheriting from unknown row ${colSource.row}`
                        );
                        continue;
                    }
                    if (colSource.node === "like") {
                        this._processLike(colSource, rowDefinition, srcRow);
                    } else {
                        this._processInherit(colSource, rowDefinition, srcRow);
                    }
                }
            }
        }

        return rows;
    }

    // ---------------------------------------------------------------------------------------------
    private _processColumn(
        colSource: LgColDefinitionColumnSource,
        rowDefinition: IRowDefinition,
        rootPadding: number,
        configuration: TableTypeConfiguration
    ): void {
        // Prepare column based on table defaults
        const column: IColumnDefinition = {
            id: colSource.id ?? "UnknownColumnId",
            type: colSource.columnType ?? "standard",
            columnClasses: colSource.columnClasses ? [...colSource.columnClasses] : [],
            typeColumnClasses: [],
            paddingLeft: rootPadding,
            paddingRight: rootPadding,
            paddingLeftOfFirst: configuration.defaultEndsPadding ?? 0,
            paddingRightOfLast: configuration.defaultEndsPadding ?? 0,
            widthTweak: 0,
            if: colSource.if,
            factor: null,
            first: false,
            last: false,
            width: colSource.width ?? null,
            flexible: false,
            isHidden: false,
            isHiddenNow: false
        };

        if (colSource.flexibilityFactor) {
            column.factor = colSource.flexibilityFactor;
            column.flexible = true;
        }

        // Update with config of the actual column type
        const config = configuration.columns[column.type.toLowerCase()];
        if (config) {
            if (config.paddingLeft != null) column.paddingLeft = config.paddingLeft;
            if (config.paddingRight != null) column.paddingRight = config.paddingRight;
            if (config.paddingLeftOfFirst != null)
                column.paddingLeftOfFirst = config.paddingLeftOfFirst;
            if (config.paddingRightOfLast != null)
                column.paddingRightOfLast = config.paddingRightOfLast;
            if (config.defaultWidth && !column.width && !column.flexible)
                column.width = config.defaultWidth;
            if (config.classNames) column.typeColumnClasses = [...config.classNames];
            if (config.isHidden != null) column.isHidden = config.isHidden;
        } else {
            console.warn(`lgColumnDefinition unknown column type ${colSource.columnType}`);
        }

        // And patch with the parameters provided
        if (colSource.paddingLeft != null) {
            column.paddingLeft = colSource.paddingLeft;
        }
        if (colSource.paddingRight) {
            column.paddingRight = colSource.paddingRight;
        }
        if (colSource.widthTweak) {
            column.widthTweak = colSource.widthTweak;
        }

        column.width = column.width || 0;

        let originalIndex: number;
        if ((originalIndex = rowDefinition.columnMap[column.id]) !== undefined) {
            const original = rowDefinition.columns[originalIndex];
            if (!original.inherited) {
                console.warn(
                    `lgColumnDefinition row ${rowDefinition.id} multiple columns with id ${column.id} `
                );
            } else if (original.flexible !== column.flexible) {
                console.warn(
                    `lgColumnDefinition row ${rowDefinition.id} column id ${column.id} cannot change flexibility `
                );
            } else {
                // Merge original (inherited) column with the definition
                if (colSource.width) original.width = column.width;

                if (colSource.columnType) {
                    original.type = column.type;
                    original.paddingLeft = column.paddingLeft;
                    original.paddingRight = column.paddingRight;
                    original.paddingLeftOfFirst = column.paddingLeftOfFirst;
                    original.paddingRightOfLast = column.paddingRightOfLast;
                    original.typeColumnClasses = column.typeColumnClasses; // no need to clone here, the column will be dropped
                    original.isHidden = column.isHidden;
                }

                if (colSource.paddingLeft != null) original.paddingLeft = column.paddingLeft;
                if (colSource.paddingRight != null) original.paddingRight = column.paddingRight;
                if (colSource.widthTweak != null) original.widthTweak = column.widthTweak;

                if (column.columnClasses.length) {
                    if (colSource.mergeColumnClasses) {
                        original.columnClasses = [
                            ...original.columnClasses,
                            ...column.columnClasses
                        ];
                    } else {
                        original.columnClasses = column.columnClasses;
                    }
                }
            }
        } else {
            rowDefinition.columnMap[column.id] = rowDefinition.columns.length;
            rowDefinition.columns.push(column);
            if (column.flexible) rowDefinition.flexibleColumns.push(column);
        }
    }

    // ---------------------------------------------------------------------------------------------
    private _processLike(
        colSource: LgColDefinitionLikeSource,
        rowDefinition: IRowDefinition,
        originalRow: IRowDefinition
    ): void {
        if (rowDefinition.columnMap[colSource.id])
            console.warn(
                `lgColumnDefinition row ${rowDefinition.id} multiple columns with id ${colSource.id}`
            );

        const fromIndex = originalRow.columnMap[colSource.column || colSource.id];
        if (fromIndex === undefined) {
            console.error(
                `lgColumnDefinition like inheriting from row ${originalRow.id} unknown column ${
                    colSource.column || colSource.id
                }`
            );
            return;
        }

        const column = _.clone(originalRow.columns[fromIndex]);
        column.id = colSource.id;
        column.first = column.last = false;

        if (colSource.columnClasses) {
            if (colSource.mergeColumnClasses) {
                column.columnClasses = [...column.columnClasses, ...colSource.columnClasses];
            } else {
                column.columnClasses = [...colSource.columnClasses];
            }
        }

        if (colSource.paddingLeft != null) column.paddingLeft = colSource.paddingLeft;
        if (colSource.paddingRight != null) column.paddingRight = colSource.paddingRight;

        if (colSource.colSpan !== undefined && colSource.colSpan !== 1) {
            const from =
                colSource.colSpan > 0 ? fromIndex : Math.max(0, fromIndex + colSource.colSpan);
            const l =
                colSource.colSpan > 0
                    ? Math.min(fromIndex + colSource.colSpan, originalRow.columns.length)
                    : fromIndex + 1;
            let width = 0;
            for (let i = from; i < l; ++i) {
                if (originalRow.columns[i].flexible) {
                    console.error("lgColumnDefinition cannot use colspan on flexible column");
                    return;
                }
                width += originalRow.columns[i].width + originalRow.columns[i].widthTweak;
                width += originalRow.columns[i].paddingLeft;
                width += originalRow.columns[i].paddingRight;
            }
            width = width - column.paddingLeft - column.paddingRight - column.widthTweak;
            column.width = width;
        }

        rowDefinition.columnMap[column.id] = rowDefinition.columns.length;
        rowDefinition.columns.push(column);
        if (column.flexible) rowDefinition.flexibleColumns.push(column);
    }

    // ---------------------------------------------------------------------------------------------
    private _processInherit(
        colSource: LgColDefinitionInheritSource,
        rowDefinition: IRowDefinition,
        originalRow: IRowDefinition
    ): void {
        let fromIndex = 0;
        let toIndex = originalRow.columns.length - 1;

        if (colSource.from) {
            fromIndex = originalRow.columnMap[colSource.from];
            if (fromIndex === undefined) {
                console.error(
                    `lgColumnDefinition inheriting from row ${colSource.row} unknown column ${colSource.from}`
                );
                return;
            }
        }

        if (colSource.to) {
            toIndex = originalRow.columnMap[colSource.to];
            if (toIndex === undefined) {
                console.error(
                    `lgColumnDefinition inheriting from row ${colSource.row} unknown end column ${colSource.to}`
                );
                return;
            }
        }

        let rename: _.Dictionary<string> = {};
        if (colSource.rename) {
            if (_.isArray(colSource.rename)) {
                for (let column = fromIndex, i = 0; column <= toIndex; ++i, ++column) {
                    if (colSource.rename.length <= i) break;
                    if (colSource.rename[i].trim() === "") continue;
                    rename[originalRow.columns[column].id] = rename[i].trim();
                }
            } else {
                rename = colSource.rename;
            }
        }

        for (let i = fromIndex; i <= toIndex; ++i) {
            const column = _.clone(originalRow.columns[i]);

            if (rename[column.id] === "-") continue;

            column.id = rename[column.id] || column.id;
            column.inherited = true;
            column.first = column.last = false;

            if (rowDefinition.columnMap[column.id])
                console.warn(
                    `lgColumnDefinition row ${rowDefinition.id} multiple columns with id ${column.id} (inheriting)`
                );

            rowDefinition.columnMap[column.id] = rowDefinition.columns.length;
            rowDefinition.columns.push(column);
            if (column.flexible) rowDefinition.flexibleColumns.push(column);
        }
    }
}
