import { Injectable } from "@angular/core";

@Injectable()
export class LgExcelBase64Service {
    readonly PADCHAR = "=";
    readonly ALPHA = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

    private _makeDOMException(): DOMException {
        // sadly in FF,Safari,Chrome you can't make a DOMException
        try {
            const constr: any = DOMException;
            return new constr(DOMException.INVALID_CHARACTER_ERR);
        } catch (tmp) {
            // not available, just passback a duck-typed equiv
            // https://developer.mozilla.org/en/Core_JavaScript_1.5_Reference/Global_Objects/Error
            // https://developer.mozilla.org/en/Core_JavaScript_1.5_Reference/Global_Objects/Error/prototype
            const ex: any = new Error("DOM Exception 5");

            // ex.number and ex.description is IE-specific.
            ex.code = ex.number = 5;
            ex.name = ex.description = "INVALID_CHARACTER_ERR";

            // Safari/Chrome output format
            ex.toString = function () {
                return "Error: " + ex.name + ": " + ex.message;
            };
            return ex;
        }
    }

    public getbyte64(s: string, i: number): number {
        // This is oddly fast, except on Chrome/V8.
        //  Minimal or no improvement in performance by using a
        //   object with properties mapping chars to value (eg. 'A': 0)
        const idx = this.ALPHA.indexOf(s.charAt(i));
        if (idx === -1) {
            throw this._makeDOMException();
        }
        return idx;
    }

    public decode(s: string | number): string {
        // convert to string
        s = "" + s;
        const getbyte64 = this.getbyte64;
        let imax = s.length;
        if (imax === 0) {
            return s;
        }

        if (imax % 4 !== 0) {
            throw this._makeDOMException();
        }

        let pads = 0;
        if (s.charAt(imax - 1) === this.PADCHAR) {
            pads = 1;
            if (s.charAt(imax - 2) === this.PADCHAR) {
                pads = 2;
            }
            // either way, we want to ignore this last block
            imax -= 4;
        }

        const x: string[] = [];
        let i: number, b10: number;
        for (i = 0; i < imax; i += 4) {
            b10 =
                (getbyte64(s, i) << 18) |
                (getbyte64(s, i + 1) << 12) |
                (getbyte64(s, i + 2) << 6) |
                getbyte64(s, i + 3);
            x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 0xff, b10 & 0xff));
        }

        switch (pads) {
            case 1:
                b10 =
                    (getbyte64(s, i) << 18) |
                    (getbyte64(s, i + 1) << 12) |
                    (getbyte64(s, i + 2) << 6);
                x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 0xff));
                break;
            case 2:
                b10 = (getbyte64(s, i) << 18) | (getbyte64(s, i + 1) << 12);
                x.push(String.fromCharCode(b10 >> 16));
                break;
        }
        return x.join("");
    }

    public getbyte(s: string, i: number): number {
        const x = s.charCodeAt(i);
        if (x > 255) {
            throw this._makeDOMException();
        }
        return x;
    }

    public encode(s: string | number): string {
        if (arguments.length !== 1) {
            throw new SyntaxError("Not enough arguments");
        }

        const padchar = this.PADCHAR;
        const alpha = this.ALPHA;
        const getbyte = this.getbyte;

        let i: number, b10: number;
        const x: string[] = [];

        // convert to string
        s = "" + s;

        const imax = s.length - (s.length % 3);

        if (s.length === 0) {
            return s;
        }
        for (i = 0; i < imax; i += 3) {
            b10 = (getbyte(s, i) << 16) | (getbyte(s, i + 1) << 8) | getbyte(s, i + 2);
            x.push(alpha.charAt(b10 >> 18));
            x.push(alpha.charAt((b10 >> 12) & 0x3f));
            x.push(alpha.charAt((b10 >> 6) & 0x3f));
            x.push(alpha.charAt(b10 & 0x3f));
        }
        switch (s.length - imax) {
            case 1:
                b10 = getbyte(s, i) << 16;
                x.push(
                    alpha.charAt(b10 >> 18) + alpha.charAt((b10 >> 12) & 0x3f) + padchar + padchar
                );
                break;
            case 2:
                b10 = (getbyte(s, i) << 16) | (getbyte(s, i + 1) << 8);
                x.push(
                    alpha.charAt(b10 >> 18) +
                        alpha.charAt((b10 >> 12) & 0x3f) +
                        alpha.charAt((b10 >> 6) & 0x3f) +
                        padchar
                );
                break;
        }
        return x.join("");
    }
}
