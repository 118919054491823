import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const LG_MESSAGE_BUS_SERVICE = new InjectionToken<IMessageBusService>(
    "LG_MESSAGE_BUS_SERVICE"
);

export interface IMessageBusService {
    getConnectionId(): string;
    on(message: string, cb: Function): ISubscriptionTicket | Promise<ISubscriptionTicket>;
    onConnectionEvent(eventName: string, cb: Function, scope?: any): ISubscriptionTicket;
    /**
     * **New tools using LgMessageBusService and .NET Core backend:**
     * Connection should be established during the app boostrap so consumers shouldn't need to care about this and assume everything is already set up - i.e. ignore this
     *
     * **Old tools using LgMessageBusOldService and .NET Framework backend**:
     * The message bus connection is being established lazily with the first use
     * and in order for it to work correctly the cookie needs to be set before your request is made.
     * So unless you're sure that the connection is already established do something similar:
     * `bus.connectionEstablished$.pipe(first()).subscribe(() => this.http.post())`
     */
    connectionEstablished$: Observable<boolean>;
}

export interface ISubscriptionTicket {
    cancel(): void;
}
