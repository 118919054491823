import { Component, HostBinding, Input, OnInit, inject } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";

import { LgHelpTooltipService } from "./lg-help-tooltip.service";
import { LG_HELP_TOOLTIP_CONFIG, LgHelpTooltipItem, LgHelpTooltipStatusEnum } from "./types/types";

@Component({
    selector: "lg-help-tooltip",
    templateUrl: "./lg-help-tooltip.template.html",
    providers: [useTranslationNamespace("FW._HelpTooltip")]
})
export class LgHelpTooltipComponent implements OnInit {
    private _helpTooltipService = inject(LgHelpTooltipService);
    private _lgConsole = inject(LgConsole);
    private _lgHelpTooltipConfig = inject(LG_HELP_TOOLTIP_CONFIG);

    private _disabled = this._lgHelpTooltipConfig.isDisabled ?? false;
    _showTooltip: boolean;

    @HostBinding("style.display")
    display = this._disabled ? "none" : "block";

    @Input({ required: true }) code!: string;

    data: LgHelpTooltipItem;

    async ngOnInit(): Promise<void> {
        if (this._disabled) return;

        this.data = await this._helpTooltipService.getValue(this.code);
        this._showTooltip = this.data != null && this.data.status !== LgHelpTooltipStatusEnum.ERROR;

        if (this._showTooltip === false) {
            this._lgConsole.warn(`HelpTooltip "${this.code}" wasn't loaded properly.`);
        }
    }
}
