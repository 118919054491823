import { PipeTransform, Pipe, Injectable, inject } from "@angular/core";
import { LgTranslateService } from "./lg-translate.service";

@Injectable()
@Pipe({
    standalone: true,
    name: "lgTranslate",
    pure: true
})
export class LgTranslatePipe implements PipeTransform {
    private _translate = inject(LgTranslateService);

    transform(query: string, ...args: any[]): string {
        if (!query || query.length === 0) {
            return query;
        }

        let interpolateParams: Record<string, any>;
        if (args[0] != null && args.length) {
            if (typeof args[0] === "string" && args[0].length) {
                // we accept objects written in the template such as {n:1}, {'n':1}, {n:'v'}
                // which is why we might need to change it to real JSON objects such as {"n":1} or {"n":"v"}
                const validArgs: string = args[0]
                    .replace(/(')?([a-zA-Z0-9_]+)(')?(\s)?:/g, '"$2":')
                    .replace(/:(\s)?(')(.*?)(')/g, ':"$3"');
                try {
                    interpolateParams = JSON.parse(validArgs);
                } catch (e) {
                    throw new SyntaxError(
                        `Wrong parameter in TranslatePipe. Expected a valid Object, received: ${args[0]}`
                    );
                }
            } else if (typeof args[0] === "object" && !Array.isArray(args[0])) {
                interpolateParams = args[0];
            }
        }

        return this._translate.translate(query, interpolateParams);
    }
}
