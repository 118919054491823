/*
TODO:
- add support for displaying the selection's icon
*/
import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    HostBinding,
    Input,
    ViewEncapsulation
} from "@angular/core";

import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { LgDropdownComponent, LgDropdownPopupComponent } from "../lg-dropdown";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-icon-menu",

    template: `<lg-icon [icon]="icon || _selectedIcon || 'icon-datasource'"></lg-icon>`,

    host: {
        "[class.lg-icon-menu--active]": "_active"
    },

    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [LgIconComponent],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgIconMenuComponent),
            multi: true
        }
    ]
})
export class LgIconMenuComponent<T extends number | string> extends LgDropdownComponent<T> {
    _selectedIcon: string | null = null;

    constructor() {
        super();
        this.hideSearch = true;
        this.matchWidth = false;
        this._attachOnLeft = false;
        this.popupClassName = "lg-dropdown-popup lg-dropdown-popup--icon-menu";
        // for now the `lg-icon-component` has no keyboard support (tabindex)
        // so the focus logic from dropdown can't work here
        // (test in pivot table when adding support)
        this.focusEnabled = false;
    }

    @HostBinding("class")
    get outerClass(): string {
        return `lg-icon-menu ${this.class}`;
    }

    /**
     * Specifies menu icon.
     *
     * @default "icon-datasource"
     */
    @Input()
    public icon = "icon-datasource";

    /**
     * Applies css class to menu icon host.
     */
    @Input() class = "";

    protected override _getPopupClass(): typeof LgDropdownPopupComponent {
        return LgDropdownPopupComponent;
    }

    protected override _updateValue(): void {
        super._updateValue();
        this._selectedIcon = this._currentValueIcons.length
            ? this._currentValueIcons[0].icon
            : null;
    }
}
