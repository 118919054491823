import {
    ElementRef,
    Component,
    Output,
    EventEmitter,
    Input,
    Renderer2,
    OnChanges,
    ViewChild,
    inject
} from "@angular/core";
import { FileUploader } from "ng2-file-upload";

import { LgSimpleChanges } from "@logex/framework/types";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Component({
    selector: "lg-upload-form",
    templateUrl: "./lg-upload-form.component.html"
})
export class LgUploadFormComponent implements OnChanges {
    _elementRef = inject(ElementRef);
    private _lgTranslate = inject(LgTranslateService);
    private _renderer = inject(Renderer2);

    @Input("file") _file: File | null = null;
    @Input("files") _files: File[] = [];

    @Input("multiple") _multipleFilesAllowed = false;
    @Input("uploader") _uploader: FileUploader;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("onFilesAdded") readonly onFilesAdded = new EventEmitter<File[]>();

    @ViewChild("fileInput") _fileInput: ElementRef<HTMLInputElement>;

    _dragging = false;
    _instance: number;

    private _internalUploader = true;
    private static _instanceCounter = 0;

    // ----------------------------------------------------------------------------------
    //
    constructor() {
        this._instance = ++LgUploadFormComponent._instanceCounter;
        // Adding styles this way in case component will be used as a base
        this._renderer.addClass(this._elementRef.nativeElement, "lg-upload-form");

        // If it used just for UI, stubbing uploader with the dummy
        this._uploader = new FileUploader({ url: "" });
    }

    ngOnChanges(changes: LgSimpleChanges<LgUploadFormComponent>): void {
        if (changes._uploader) {
            this._internalUploader = false;
        }
        if (this._internalUploader && changes._file) {
            this._uploader.clearQueue();
            if (this._fileInput && !this._file) this._fileInput.nativeElement.value = "";
            if (this._file) this._uploader.addToQueue([this._file]);
        }
        if (this._internalUploader && changes._files) {
            // we assume both file and files won't be used at once
            this._uploader.clearQueue();
            if (this._fileInput && !this._files) this._fileInput.nativeElement.value = "";
            if (this._files) this._uploader.addToQueue(this._files);
        }
    }

    _getFiles(): File[] {
        if (this._file) {
            return [this._file];
        } else if (this._files) {
            return this._files;
        }

        return [];
    }

    _draggingOverInput(dragging: boolean): void {
        this._dragging = dragging;
    }

    _fileSelected($e: Event): void {
        this._dropped(($e.target as HTMLInputElement).files);
    }

    _dropped(files: FileList): void {
        this.onFilesAdded.emit(Array.from(files));
    }

    _getLength(uploader: FileUploader): number {
        let length = 0;
        uploader.queue.forEach(element => (length += element.file.size));
        return length;
    }

    _getProgress(uploader: FileUploader): number {
        let length = 0;
        uploader.queue.forEach(element => (length += element.file.size));
        return (length / 100) * uploader.progress;
    }
}
