import _ from "lodash";
import { Injectable, ViewContainerRef, inject } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { take } from "rxjs/operators";

import { IDialogOptions, IDialogComponent } from "./lg-dialog.types";
import { LgDialogService } from "./lg-dialog.service";
import { LG_APPLICATION_EVENT_TRACER } from "@logex/framework/core";

export interface ILgDialogFactoryExtensions<T> {
    /**
     * Must be called in constructor context, otherwise ViewContainerRef must be passed as a parameter.
     */
    bindViewContainerRef(viewRef?: ViewContainerRef): T & ILgDialogFactoryExtensions<T>;
}

type Constructor<T> = new (...args: any[]) => T;
type DialogFactoryConstructor<T, M extends keyof T> = new (factory: LgDialogFactory) => Pick<T, M> &
    ILgDialogFactoryExtensions<Pick<T, M>>;

// ---------------------------------------------------------------------------------------------
//  Factorizer
// ---------------------------------------------------------------------------------------------
export function getDialogFactoryBase<T extends Record<M1, Function>, M1 extends keyof T>(
    src: Constructor<T>,
    method1: M1
): DialogFactoryConstructor<T, M1>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2, Function>,
    M1 extends keyof T,
    M2 extends keyof T
>(src: Constructor<T>, method1: M1, method2: M2): DialogFactoryConstructor<T, M1 | M2>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3
): DialogFactoryConstructor<T, M1 | M2 | M3>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6 | M7, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T,
    M7 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6,
    method7: M7
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6 | M7>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T,
    M7 extends keyof T,
    M8 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6,
    method7: M7,
    method8: M8
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8 | M9, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T,
    M7 extends keyof T,
    M8 extends keyof T,
    M9 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6,
    method7: M7,
    method8: M8,
    method9: M9
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8 | M9>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8 | M9 | M10, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T,
    M7 extends keyof T,
    M8 extends keyof T,
    M9 extends keyof T,
    M10 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6,
    method7: M7,
    method8: M8,
    method9: M9,
    method10: M10
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8 | M9 | M10>;

export function getDialogFactoryBase(
    dialogComponent: new (...args: any[]) => any,
    ...exposedMethodNames: string[]
): any {
    const create = function (this: any): void {
        this._factory = inject(LgDialogFactory);
        this._factoryViewRef = undefined;
    };

    for (const methodName of exposedMethodNames) {
        create.prototype[methodName] = function (...parameters: any[]) {
            const factory: LgDialogFactory = this._factory;
            return factory.create(this._factoryViewRef, dialogComponent, methodName, parameters);
        };
    }

    create.prototype.bindViewContainerRef = function (viewContainerRef = inject(ViewContainerRef)) {
        const child = new (create as any)();
        child._factoryViewRef = viewContainerRef;
        return child;
    };

    return create;
}

// ---------------------------------------------------------------------------------------------
//  LgDialogFactory implementation
// ---------------------------------------------------------------------------------------------
@Injectable()
export class LgDialogFactory {
    private _dialogService = inject(LgDialogService);
    private _tracerService = inject(LG_APPLICATION_EVENT_TRACER);

    create(
        viewContainerRef: ViewContainerRef,
        component: ComponentType<IDialogComponent<any>>,
        methodName: string,
        currentDialogOptions: any[]
    ): any {
        const options: IDialogOptions = {
            data: currentDialogOptions,
            viewContainerRef
        };

        let result: any;

        this._dialogService.show(component, options, (_showOptions, instance, doFinish) => {
            // eslint-disable-next-line prefer-spread
            result =
                methodName && (instance as any)[methodName].apply(instance, currentDialogOptions);

            const runConfiguration = (): void => {
                let configuration: IDialogOptions = {
                    title: this._helperGet(instance, "_title", "Dialog"),
                    icon: this._helperGet(instance, "_icon", ""),
                    helpUrl: this._helperGet(instance, "_helpUrl", null),
                    allowClose: this._helperGet(instance, "_allowClose", true),
                    allowMaximize: this._helperGet(instance, "_allowMaximize", false),
                    relatedTo: this._helperGet(instance, "_relatedTo", null),
                    minHeight: this._helperGet(instance, "_minHeight", null),
                    type: this._helperGet(
                        instance,
                        "_dialogType",
                        // backwards compatibility due to old typo
                        this._helperGet(instance, "dialogType", null)
                    ),
                    dialogClass: this._helperGet(instance, "_dialogClass", null),
                    dialogBodyClass: this._helperGet(instance, "_dialogBodyClass", null),
                    closeOnEsc: this._helperGet(instance, "_closeOnEsc", true),
                    closeOnOverlayClick: this._helperGet(instance, "_closeOnOverlayClick", false),
                    ready: this._helperGet(instance, "_ready", null),
                    dialogHeaderTemplate: this._helperGet(instance, "_dialogHeaderTemplate", null),
                    forceCloseOnNavigation: this._helperGet(
                        instance,
                        "_forceCloseOnNavigation",
                        true
                    ),
                    dialogButtons: this._helperGet(instance, "_dialogButtons", undefined),
                    scrollerType: this._helperGet(instance, "_scrollerType", "vertical")
                };

                if (instance._onClose) configuration.onClose = _.bind(instance._onClose, instance);
                if (instance._tryClose)
                    configuration.tryClose = _.bind(instance._tryClose, instance);

                if (instance._configure != null) {
                    configuration =
                        // eslint-disable-next-line prefer-spread
                        instance._configure.apply(instance, [configuration]) || configuration;
                }

                doFinish(configuration);

                if (instance._activate) {
                    instance._activate.apply(instance);
                }
            };

            if (instance._initializationDone) {
                instance._initializationDone.pipe(take(1)).subscribe(runConfiguration);
            } else {
                runConfiguration();
            }
        });

        this._tracerService.trackEvent("Dialogs", "Opened", component.name);

        return result;
    }

    private _helperGet<T extends IDialogComponent<any, any>>(
        instance: T,
        member: keyof T,
        def: any
    ): any {
        const prop = instance[member];
        return prop === undefined ? def : _.isFunction(prop) ? prop.apply(instance) : prop;
    }
}
