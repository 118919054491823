import { InteractivityChecker } from "@angular/cdk/a11y";
import { elementHasClass } from "@logex/framework/utilities";

export function getFirstTabbableElementAfter(
    root: HTMLElement,
    after: HTMLElement,
    found: { value: boolean },
    forOverlay: boolean,
    interactivityChecker: InteractivityChecker
): HTMLElement | null {
    if (
        found.value &&
        interactivityChecker.isFocusable(root) &&
        interactivityChecker.isTabbable(root)
    ) {
        if (!forOverlay || !elementHasClass(root, "disable-overlay-focus")) {
            return root;
        }
    }

    if (root === after) found.value = true;

    // Iterate in DOM order. Note that IE doesn't have `children` for SVG so we fall
    // back to `childNodes` which includes text nodes, comments etc.
    const children = root.children || root.childNodes;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < children.length; i++) {
        const tabbableChild =
            children[i].nodeType === document.ELEMENT_NODE
                ? getFirstTabbableElementAfter(
                      children[i] as HTMLElement,
                      after,
                      found,
                      forOverlay,
                      interactivityChecker
                  )
                : null;

        if (tabbableChild) {
            return tabbableChild;
        }
    }

    return null;
}
