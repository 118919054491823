import _ from "lodash";
import { Component, ViewEncapsulation, Injectable, OnDestroy, inject } from "@angular/core";
import { BehaviorSubject, of, Observable, forkJoin, Subject, isObservable } from "rxjs";
import { take, takeUntil, tap, map } from "rxjs/operators";

import { useTranslationNamespace, LgTranslateService } from "@logex/framework/lg-localization";
import {
    IDialogComponent,
    getDialogFactoryBase,
    IDropdownDefinition,
    IDropdownGroup,
    LgDialogRef,
    LgPromptDialog
} from "@logex/framework/ui-core";

import {
    IItemClusterAdapter,
    IItemCluster,
    IItemClusterFilterTexts,
    IItemClusterCopyGroup
} from "./lg-itemcluster-filter.types";

@Component({
    selector: "lg-itemcluster-dialog",
    templateUrl: "./lg-itemcluster-dialog.component.html",
    encapsulation: ViewEncapsulation.None,
    viewProviders: [useTranslationNamespace("FW._Directives._ItemClusterFilter")]
})
export class LgItemClusterDialogComponent
    implements IDialogComponent<LgItemClusterDialogComponent>, OnDestroy
{
    private _lgTranslate = inject(LgTranslateService);
    private _dialogRef = inject(LgDialogRef<LgItemClusterDialogComponent>);
    private _promptDialog = inject(LgPromptDialog).bindViewContainerRef();

    _title: string;
    readonly _dialogClass = "lg-dialog lg-dialog--4col";
    readonly _ready = new BehaviorSubject<boolean>(false);

    _readonly: boolean;
    _name: string;
    _description: string;
    _itemCount: number;
    _items: any[];
    _creating: boolean;
    _modified = false;
    _nameValid = false;
    _working = false;

    _copyFromDropdown: IDropdownDefinition<string>;
    _copyFrom = "0_0";

    _texts: IItemClusterFilterTexts;

    private _cluster: IItemCluster | null;
    private _clusterId: string | null;
    private _adapter: IItemClusterAdapter<any>;
    private _existingClusters: _.Dictionary<IItemCluster>;
    private readonly _destroyed$ = new Subject<void>();
    private _addResolve: () => void;
    private _editResolve: (deleted?: boolean) => void;

    private _visibleItems: any[];
    private _copyFromGroups: _.Dictionary<{ groupId: string | null; entryId: string }>;

    addCluster(adapter: IItemClusterAdapter<any>): Promise<void> {
        this._prepare(adapter);
        this._title = this._lgTranslate.translate(this._texts.newTitleLc);
        this._readonly = false;
        this._name = "";
        this._description = "";
        this._cluster = null;
        this._clusterId = null;
        this._items = [];
        this._itemCount = 0;
        this._creating = true;
        this._prepareCopySources();
        return new Promise(resolve => (this._addResolve = resolve));
    }

    editCluster(
        adapter: IItemClusterAdapter<any>,
        clusterId: string,
        cluster: IItemCluster,
        readonly: boolean
    ): Promise<boolean> {
        this._prepare(adapter);
        this._title = this._lgTranslate.translate(
            readonly ? this._texts.viewTitleLc : this._texts.editTitleLc
        );
        this._readonly = readonly;
        this._cluster = cluster;
        this._clusterId = clusterId;
        this._name = cluster.name;
        this._description = cluster.description;
        this._creating = false;
        this._nameValid = true;

        this._items = this._adapter.getClusterItems(clusterId);
        this._itemCount = this._items.length;

        if (readonly) {
            this._ready.next(true);
        } else {
            this._prepareCopySources();
        }

        return new Promise<boolean>(resolve => (this._editResolve = resolve));
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _nameChanged(): void {
        this._validateName();
        this._modified = true;
    }

    _editItems(): void {
        if (this._readonly) {
            this._adapter.viewItems(this._items, this._dialogRef);
        } else {
            this._adapter
                .pickItems(this._items, this._creating ? null : this._clusterId, this._dialogRef)
                .pipe(takeUntil(this._destroyed$), take(1))
                .subscribe(items => {
                    this._items = items;
                    this._itemCount = items.length;
                    this._modified = true;
                });
        }
    }

    _tryClose(): boolean {
        this._confirmClose().then(
            () => this._dialogRef.close(),
            () => undefined
        );
        return false;
    }

    _save(): void {
        if (this._description === "") this._description = null;

        const toSave: IItemCluster = {
            name: this._name,
            description: this._description
        };

        const result = this._creating
            ? this._adapter.addCluster(toSave, this._items)
            : this._adapter
                  .updateCluster(this._clusterId, toSave, this._items)
                  .pipe(map(() => this._clusterId));

        this._working = true;
        result
            .pipe(
                takeUntil(this._destroyed$),
                take(1),
                tap({ next: () => (this._working = false), error: () => (this._working = false) })
            )
            .subscribe({
                next: () => {
                    this._dialogRef.close();
                    if (this._creating) {
                        this._addResolve();
                    } else {
                        this._editResolve(false);
                    }
                },
                error: error => this._errorDialog(error)
            });
    }

    _remove(): void {
        this._promptDialog
            .confirmLc(".Confirm_delete_title", this._texts.confirmDeleteLc)
            .then(res => {
                if (res === "ok") {
                    this._working = true;
                    this._adapter
                        .deleteCluster(this._clusterId)
                        .pipe(
                            takeUntil(this._destroyed$),
                            take(1),
                            tap(
                                () => (this._working = false),
                                () => (this._working = false)
                            )
                        )
                        .subscribe({
                            next: () => {
                                this._dialogRef.close();
                                this._editResolve(true);
                            },
                            error: error => this._errorDialog(error)
                        });
                }
            });
    }

    _cancel(): void {
        this._confirmClose().then(
            () => this._dialogRef.close(),
            () => undefined
        );
    }

    _close(): void {
        this._dialogRef.close();
    }

    private _prepare(adapter: IItemClusterAdapter<any>): void {
        this._adapter = adapter;
        this._existingClusters = adapter.getClusters();

        this._texts = {
            viewTitleLc: ".View_title",
            editTitleLc: ".Edit_title",
            newTitleLc: ".New_title",
            itemsLabelLc: ".Items_label",
            itemCountLc: ".Items_count",
            editItemsButtonLc: ".Edit_items_button",
            viewItemsButtonLc: ".View_items_button",
            copyCurrentFilterLc: ".Copy_filter_item",
            confirmCancelLc: ".Confirm_cancel_text",
            confirmDeleteLc: ".Confirm_delete_text",
            ...(adapter.texts || {})
        };
    }

    private _prepareCopySources(): void {
        let visible: Observable<any[]>;
        let groups: Observable<IItemClusterCopyGroup[]>;

        if (this._adapter.getVisibleItems) {
            const src = this._adapter.getVisibleItems();
            visible = isObservable(src) ? src : of(src);
        } else {
            visible = of(null);
        }

        if (this._adapter.getCopyFromGroups) {
            const src = this._adapter.getCopyFromGroups();
            groups = isObservable(src) ? src : of(src);
        } else {
            groups = of(null);
        }

        // Note: we could postpone the fetch of visible until the user choses it, should we?
        forkJoin(visible, groups)
            .pipe(takeUntil(this._destroyed$), take(1))
            .subscribe(([visibleItems, copyGroups]) => {
                this._processCopySources(visibleItems, copyGroups);
                this._ready.next(true);
            });
    }

    private _processCopySources(
        visibleItems: null | any[],
        copyGroups: null | IItemClusterCopyGroup[]
    ): void {
        // First group: no copy
        const dropdownGroups: IDropdownGroup[] = [
            {
                entries: [
                    {
                        id: "0_0",
                        name: "-"
                    }
                ]
            }
        ];

        // Second group: current page filter
        if (visibleItems && visibleItems.length > 0) {
            this._visibleItems = visibleItems;
            dropdownGroups.push({
                name: this._lgTranslate.translate(".Copy_filter_group"),
                entries: [
                    {
                        id: "1_0",
                        name: this._lgTranslate.translate(this._texts.copyCurrentFilterLc!)
                    }
                ]
            });
        }

        // Third group: other clusters
        const clusterIds = _.filter(_.keys(this._existingClusters), id => id !== this._clusterId);
        if (clusterIds.length > 0) {
            dropdownGroups.push({
                name: this._lgTranslate.translate(".Copy_cluster_group"),
                entries: _.map(clusterIds, id => ({
                    id: "2_" + id,
                    name: this._existingClusters[id].name,
                    help: this._existingClusters[id].description
                }))
            });
        }

        // Further groups: tool specific
        if (copyGroups && copyGroups.length > 0) {
            this._copyFromGroups = {};
            let cnt = 0;
            _.each(copyGroups, group => {
                if (group.entries.length === 0) return;

                dropdownGroups.push({
                    help: group.help,
                    entries: _.map(group.entries, entry => {
                        const id = "3_" + cnt;
                        ++cnt;
                        this._copyFromGroups[id] = { groupId: group.id, entryId: entry.id };
                        return {
                            id,
                            name: entry.name,
                            help: entry.help
                        };
                    })
                });
            });
        }

        if (dropdownGroups.length > 1) {
            this._copyFromDropdown = {
                groups: dropdownGroups,
                groupName: "name"
            };
        }
    }

    _copyItems(): void {
        const parts = this._copyFrom.split("_", 2);
        switch (parts[0]) {
            case "0": // none
                break;
            case "1": // visible
                this._addItems(this._visibleItems);
                break;
            case "2": // other cluster
                this._addItems(this._adapter.getClusterItems(parts[1]));
                break;
            case "3": // custom groups
                const groupIds = this._copyFromGroups[this._copyFrom];
                const src = this._adapter.getCopyFromItems(groupIds.groupId, groupIds.entryId);
                if (isObservable(src)) {
                    this._working = true;
                    src.pipe(takeUntil(this._destroyed$), take(1)).subscribe(
                        items => {
                            this._addItems(items);
                        },
                        null,
                        () => {
                            this._working = false;
                        }
                    );
                } else {
                    this._addItems(src);
                }
                break;
        }
    }

    private _addItems(items: any[]): void {
        this._items = _(this._items).concat(items).uniq().value();
        this._itemCount = this._items.length;
        this._modified = true;
    }

    private _validateName(): void {
        if (this._name == null) this._name = "";
        this._name = this._name.trim();
        if (this._name === "") {
            this._nameValid = false;
            return;
        }

        this._nameValid = !_(this._existingClusters)
            .keys()
            .filter(id => id !== this._clusterId)
            .some(
                id =>
                    this._existingClusters[id].name.localeCompare(this._name, undefined, {
                        sensitivity: "accent"
                    }) === 0
            );
    }

    private _confirmClose(): Promise<void> {
        if (this._readonly || !this._modified) return Promise.resolve();

        return this._promptDialog
            .confirmLc(".Confirm_cancel_title", this._texts.confirmCancelLc, {
                buttons: [
                    LgPromptDialog.OK_BUTTON,
                    { ...LgPromptDialog.CANCEL_BUTTON, isReject: true }
                ]
            })
            .then(() => undefined);
    }

    private _errorDialog(error: any): Promise<string> {
        return this._promptDialog.alert(
            this._lgTranslate.translate(".Error_message_title"),
            error.message || this._lgTranslate.translate(".Error_message_text"),
            {
                dialogType: "alert",
                columns: 5
            }
        );
    }
}

@Injectable({ providedIn: "root" })
export class LgItemClusterDialog extends getDialogFactoryBase(
    LgItemClusterDialogComponent,
    "addCluster",
    "editCluster"
) {}
