export function cloneElementWithStyles(source: Element): Node {
    const clone = source.cloneNode(true) as HTMLElement;

    const srcElements = <HTMLCollectionOf<HTMLElement>>source.getElementsByTagName("*");
    const dstElements = <HTMLCollectionOf<HTMLElement>>clone.getElementsByTagName("*");

    for (let i = srcElements.length; i > 0; i--) {
        cloneStyles(srcElements[i - 1], dstElements[i - 1]);
    }

    return clone;
}

function cloneStyles(source: HTMLElement, destination: HTMLElement): void {
    const sourceElementStyles = document.defaultView.getComputedStyle(source);

    destination.style.cssText = Array.from(sourceElementStyles).reduce(
        (str, property) => `${str}${property}:${sourceElementStyles.getPropertyValue(property)};`,
        ""
    );
}
