import {
    Component,
    Input,
    TemplateRef,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    ViewContainerRef,
    EmbeddedViewRef,
    ViewChild,
    Injector,
    inject
} from "@angular/core";
import { LgPlaceholderStorageService } from "./lg-placeholder-storage.service";

/**
 * Defines a placeholder, which will be filled by a content of the specified name (see lgPlaceholderContent). The
 * inside of the placeholder itself defines a default, which will be used if no other content definition exists (this
 * is not part of the regular stack and thus has really the lowest priority). The name attribute supports interpolation.
 *
 * Unlike lg-placeholder, lg-sibling-placeholder renders the content as a sibling (like routerOutlet, for example). This can be useful for styling purposes
 *
 * Usage:
 * <lg-sibling-placeholder name="app.welcome" [context]="{color:'red'}">
 *    <b>I don't know, how to welcome you!</b>
 * </lg-sibling-placeholder>
 *
 * The content is normally rendered using the context specified for the placeholder. Typically this is what
 * you want. It can be however overriden for a special cases.
 *
 */
@Component({
    standalone: true,
    selector: "lg-sibling-placeholder",
    template: ` <ng-template #default><ng-content></ng-content></ng-template> `,
    host: {
        style: "display:none"
    }
})
export class LgSiblingPlaceholderComponent implements OnChanges, OnDestroy {
    private _storage = inject(LgPlaceholderStorageService);
    private _viewContainerRef = inject(ViewContainerRef);
    @Input({ required: true }) name!: string;

    @Input("context") defaultContext?: any;

    @ViewChild("default", { static: true }) _defaultTemplate: TemplateRef<any>;

    private _storedContext: any;
    private _template: TemplateRef<any> | undefined = undefined;
    private _injector: Injector | undefined = undefined;
    private _viewRef: EmbeddedViewRef<any>;
    private _unsubscriber: () => void;
    private _refreshPending = false;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.name) {
            this._unsubscribe();

            if (changes.name.currentValue) {
                this._unsubscriber = this._storage.watchContent(
                    this.name,
                    (_name, content) => this._updateTemplate(content?.template, content?.injector),
                    (_name, context) => this._updateContext(context)
                );
            }
        }
    }

    public ngOnDestroy(): void {
        this._unsubscribe();
        this._detach();
    }

    private _unsubscribe(): void {
        if (this._unsubscriber) {
            this._unsubscriber();
            this._unsubscriber = null;
            this._template = null;
            this._storedContext = null;
        }
    }

    private _detach(): void {
        if (this._viewRef) {
            this._viewContainerRef.remove(this._viewContainerRef.indexOf(this._viewRef));
            this._viewRef = null;
        }
    }

    private _attach(): void {
        this._viewRef = this._viewContainerRef.createEmbeddedView(
            this._template ?? this._defaultTemplate,
            this._storedContext ?? this.defaultContext,
            { injector: this._injector }
        );
    }

    private _updateTemplate(
        content: TemplateRef<any> | undefined,
        injector: Injector | undefined
    ): void {
        this._template = content;
        this._injector = injector;
        this._scheduleRefresh();
    }

    private _updateContext(context: any): void {
        this._storedContext = context;
        // todo: should we render the default template? Is it useful?
        if (this._template) {
            this._scheduleRefresh();
        }
    }

    private _scheduleRefresh(): void {
        if (this._refreshPending) return;
        this._refreshPending = true;
        Promise.resolve().then(() => {
            this._detach();
            this._attach();
            this._refreshPending = false;
        });
    }
}
