import { Directive, ElementRef, OnDestroy, OnInit, inject } from "@angular/core";
import { createCustomEvent, isElementAttachedToDocument } from "@logex/framework/utilities";

export type LgDetachmentNotificationEvent = CustomEvent<{ attached: boolean }>;
export const LG_DETACHMENT_NOTIFICATION_EVENT_NAME = "LgDetachmentNotification";
export const LG_DETACHMENT_NOTIFICATION_LISTENER = "LgDetachmentNotificationListener";

/**
 * This directive notifies its children of being attached/detached. In order to listen, the element
 * must have the LG_DETACHMENT_NOTIFICATION_LISTENER attribute
 * (see LgDetachedPreserveScrollDirective as an example).
 * The directive should be placed on element right above ng-content which drives the content's visibility:
 * check LgPanelComponent's template as an example.
 */
@Directive({
    standalone: true,
    selector: "[lgDetachmentNotifier]"
})
export class LgDetachmentNotifierDirective implements OnInit, OnDestroy {
    private _elementRef = inject(ElementRef<HTMLElement>);

    ngOnInit(): void {
        const attached = isElementAttachedToDocument(this._elementRef.nativeElement);
        if (attached) {
            // todo: should we delay this to allow for ngIfs inside?
            // For now let's prefer predictable timing.
            this._sendEvent(true);
        }
    }

    ngOnDestroy(): void {
        this._sendEvent(false);
    }

    private _sendEvent(attached: boolean): void {
        const event = createCustomEvent(
            LG_DETACHMENT_NOTIFICATION_EVENT_NAME,
            { attached },
            false,
            false
        );
        this._elementRef.nativeElement
            .querySelectorAll(`[${LG_DETACHMENT_NOTIFICATION_LISTENER}]`)
            .forEach((el: { dispatchEvent: (arg0: CustomEvent<any>) => void }) => {
                el.dispatchEvent(event);
            });
    }
}
