import { Component, Input, OnInit } from "@angular/core";

import { LgCopyBase } from "./lg-copy-base";
import { ICopyColumnInfo } from "./copy-paste.types";

@Component({
    selector: "lg-copy-button",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <lg-button
            *ngIf="!asIcon"
            icon="icon-copy"
            [buttonClass]="buttonClass"
            titleLc="FW._Directives.CopyButton_Tooltip"
            (click)="copyData()"
        ></lg-button>

        <lg-icon
            *ngIf="asIcon"
            icon="icon-copy"
            [class]="iconClass"
            (click)="copyData()"
            [lgTooltip]="'FW._Directives.CopyButton_Tooltip' | lgTranslate"
            [clickable]="true"
        ></lg-icon>
    `
})
export class LgCopyButtonComponent extends LgCopyBase<ICopyColumnInfo> implements OnInit {
    protected _buttonClass: string;

    // ----------------------------------------------------------------------------------
    @Input() columnsDefinitionsRef?: HTMLElement;

    @Input() asIcon = false;

    @Input() set buttonClass(value: string) {
        this._buttonClass = value;
    }

    get buttonClass(): string {
        if (this._buttonClass != null) return this._buttonClass;
        return "button--condensed disable-overlay-focus";
    }

    // ----------------------------------------------------------------------------------
    override ngOnInit(): void {
        super.ngOnInit();

        if (!this._columns || !this._columns.length) {
            const columnElements = Array.from(this.columnsDefinitionsRef.children);

            this._columns = columnElements.map(x => ({
                field: x.getAttribute("field"),
                name: x.getAttribute("name")
            }));
        }
    }

    copyData(): void {
        const data = this.onGetData();
        this.copyDataDo(data);
    }
}
