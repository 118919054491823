import { Directive, OnInit, ElementRef, Renderer2, OnDestroy, inject } from "@angular/core";

@Directive({
    standalone: true,
    selector: "[lgStyledRadio]"
})
export class LgStyledRadioDirective implements OnInit, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    private _label: HTMLElement;
    private _indicator: HTMLElement;
    private _svgOn: HTMLElement;
    private _useOn: HTMLElement;
    private _svgOff: HTMLElement;
    private _useOff: HTMLElement;
    private _shade: HTMLElement;
    private _unsubscribe: () => void;

    ngOnInit(): void {
        const native: Element = this._elementRef.nativeElement;

        if (native.nodeName !== "INPUT" || native.getAttribute("type") !== "radio") {
            if (window.console) {
                console.log("Invalid lgStyledRadio application on element %o", native);
            }
            return;
        }

        this._label = this._renderer.createElement("label");
        this._renderer.addClass(this._label, "lg-styled-radio");
        this._indicator = this._renderer.createElement("span");
        this._svgOn = this._renderer.createElement("svg", "svg");
        this._renderer.addClass(this._svgOn, "lg-styled-radio__on");
        this._renderer.setAttribute(this._svgOn, "viewBox", "0 0 24 24");
        this._useOn = this._renderer.createElement("use", "svg");
        this._renderer.setAttribute(this._useOn, "href", "#icon-radio-button-checked", "xlink");
        this._renderer.appendChild(this._indicator, this._svgOn);
        this._renderer.appendChild(this._svgOn, this._useOn);

        this._svgOff = this._renderer.createElement("svg", "svg");
        this._renderer.addClass(this._svgOff, "lg-styled-radio__off");
        this._renderer.setAttribute(this._svgOff, "viewBox", "0 0 24 24");
        this._useOff = this._renderer.createElement("use", "svg");
        this._renderer.setAttribute(this._useOff, "href", "#icon-radio-button-unchecked", "xlink");
        this._renderer.appendChild(this._indicator, this._svgOff);
        this._renderer.appendChild(this._svgOff, this._useOff);

        this._shade = this._renderer.createElement("span");
        this._renderer.addClass(this._shade, "lg-styled-radio__shade");
        this._renderer.appendChild(this._indicator, this._shade);

        this._renderer.insertBefore(this._renderer.parentNode(native), this._label, native);
        this._renderer.appendChild(this._label, native);
        this._renderer.appendChild(this._label, this._indicator);

        this._unsubscribe = this._renderer.listen(this._label, "click", (event: Event) => {
            event.stopPropagation();
        });

        // original code listened on element too but that doesn't seem to be necessary
    }

    ngOnDestroy(): void {
        this._unsubscribe();
        if (this._label.parentNode) this._renderer.removeChild(this._label.parentNode, this._label);
        if (this._renderer.destroyNode) {
            this._renderer.destroyNode(this._label);
            this._renderer.destroyNode(this._indicator);
            this._renderer.destroyNode(this._svgOn);
            this._renderer.destroyNode(this._useOn);
            this._renderer.destroyNode(this._svgOff);
            this._renderer.destroyNode(this._useOff);
            this._renderer.destroyNode(this._shade);
        }
    }
}
