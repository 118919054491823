import {
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    IMatomoDimension,
    MatomoStatsUrl
} from "./matomo-configuration";

export class EmptyMatomoConfiguration implements IMatomoConfiguration {
    siteId: number;
    statUrl: string;
    commonDimensionIds: IMatomoCommonDimensionIds;
    customDimensions?: IMatomoDimension[];
    registry?: string;
    autoInit = true;
    autoTrack = true;

    constructor(
        siteId: number,
        commonDimensionIds: IMatomoCommonDimensionIds,
        statUrl?: MatomoStatsUrl,
        registry?: string,
        customDimensions?: IMatomoDimension[]
    ) {
        this.siteId = siteId;
        this.commonDimensionIds = commonDimensionIds;

        this.statUrl = statUrl ?? MatomoStatsUrl.Logex;

        if (registry) {
            this.registry = registry;
        }
        if (customDimensions) {
            this.customDimensions = customDimensions;
        }
    }

    doNotDoMatTracking(): boolean {
        return false;
    }
}
