import { ElementRef } from "@angular/core";

let implementation: Function | null = null;

export function closestElement(
    element: ElementRef | HTMLElement,
    selector: string
): HTMLElement | null {
    if (!implementation) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
        if (typeof Element.prototype.closest === "function") {
            implementation = (el: HTMLElement, sel: string) => el.closest(sel);
        } else {
            const matches =
                Element.prototype.matches ||
                (Element.prototype as any).msMatchesSelector ||
                Element.prototype.webkitMatchesSelector;
            implementation = (el: Node | null, sel: string) => {
                if (!document.documentElement.contains(el)) return null;

                do {
                    if (matches.call(el, sel)) return el;
                    if (el !== null) el = el.parentElement || el.parentNode;
                } while (el !== null && el.nodeType === 1);
                return null;
            };
        }
    }

    let target: HTMLElement;
    if ("nativeElement" in element) {
        target = element.nativeElement;
    } else {
        target = element;
    }

    return implementation(target, selector);
}
