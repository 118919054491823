import _ from "lodash";
import {
    IDefinition,
    IRowDefinition,
    ICalculatedRowDefinition,
    IColumnDefinition
} from "./internal.types";

export interface LgColDefinitionColumnParameters {
    width: number;
    paddingLeft: number;
    paddingRight: number;
    first: boolean;
    last: boolean;
    hidden: boolean;
    columnClasses: string[];
    rowColumnClasses: string[];
}

export class LgColDefinitionInstantiated {
    private _calculatedRows: _.Dictionary<ICalculatedRowDefinition>;
    private _rowWidth: number;

    public constructor(definition: IDefinition, rowWidth: number) {
        this._rowWidth = rowWidth;
        this._processDefinition(definition);
    }

    public getColumnParameters(
        rowId: string | false,
        columnId: string,
        colspan: number
    ): LgColDefinitionColumnParameters {
        let def: ICalculatedRowDefinition;
        let colIndex: number;

        // when rowId is specified, try to take rowId, columnId
        if (rowId) {
            def = this._calculatedRows[rowId];
            if (def) {
                colIndex = def.columnMap[columnId];
            }
        }

        // if not found and columnId contains dot, try to assume it overrides the rowId
        let dotIndex: number;
        if (colIndex === undefined && (dotIndex = columnId.indexOf(".")) !== -1) {
            rowId = columnId.substring(0, dotIndex);
            columnId = columnId.substring(dotIndex + 1);
            def = this._calculatedRows[rowId];
            if (def) {
                colIndex = def.columnMap[columnId];
            }
        }

        if (colIndex === undefined) {
            console.warn("Unknown lg-col reference to row %o column %o", rowId, columnId);
            return null;
        }

        const column = def.columns[colIndex];
        const columnClasses = _.concat(column.columnClasses, column.typeColumnClasses);
        if (column.first) columnClasses.push("first");
        if (column.last) columnClasses.push("last");

        let width: number;
        let first = column.first;
        let last = column.last;

        if (colspan && colspan !== 1) {
            const from = colspan > 0 ? colIndex : Math.max(0, colIndex + colspan);
            const to =
                colspan > 0 ? Math.min(colIndex + colspan - 1, def.columns.length - 1) : colIndex;
            width = 0;
            // Sum the whole width of the columns including padding
            for (let i = from; i <= to; ++i) {
                if (def.columns[i].isHiddenNow) continue;
                width += def.widths[i] + def.columns[i].widthTweak;
                width += def.columns[i].paddingLeft;
                width += def.columns[i].paddingRight;
            }

            // widthTweak and paddings are part of the class/style, so we need to subtract it. Now we have the value the column should use as width
            if (!column.isHiddenNow) {
                width =
                    width -
                    def.columns[colIndex].widthTweak -
                    def.columns[colIndex].paddingLeft -
                    def.columns[colIndex].paddingRight;
            }

            // If we've become first, the assumption changes
            if (from === def.firstVisible && !column.first) {
                first = true;
                columnClasses.push("first");
                // We assumed we had more space, so correct the total allocated space by the real padding of the left column
                width += -def.columns[from].paddingLeft + def.columns[from].paddingLeftOfFirst;
                // And our actual padding will be different too, so modify that
                width += column.paddingLeft - column.paddingLeftOfFirst;
            }
            if (to === def.lastVisible && !column.last) {
                last = true;
                columnClasses.push("last");
                // analogous to the first path
                width +=
                    column.paddingRight -
                    column.paddingRightOfLast -
                    def.columns[to].paddingRight +
                    def.columns[to].paddingRightOfLast;
            }
        } else {
            width = def.widths[colIndex];
        }

        if (column.isHiddenNow && !width) columnClasses.push("hidden");

        return {
            width,
            first,
            last,
            paddingLeft: column.isHiddenNow ? 0 : column.paddingLeft,
            paddingRight: column.isHiddenNow ? 0 : column.paddingRight,
            hidden: column.isHiddenNow && !width,
            columnClasses,
            rowColumnClasses: def.columnClasses
        };
    }

    private _processDefinition(definition: IDefinition): void {
        this._calculatedRows = {};

        _.each(definition, (srcRow: IRowDefinition, srcId: string) => {
            const row: ICalculatedRowDefinition = (this._calculatedRows[srcId] = {
                columns: srcRow.columns,
                columnMap: srcRow.columnMap,
                widths: [],
                columnClasses: srcRow.columnClasses,
                firstVisible: srcRow.firstVisible,
                lastVisible: srcRow.lastVisible
            });

            for (const col of row.columns) {
                row.widths.push(col.isHiddenNow ? 0 : col.width);
            }

            const toAllocate = this._rowWidth - srcRow.allocatedWidth;
            let toAllocateLeft = toAllocate;
            let flexible: number;
            const l = srcRow.flexibleColumns.length;
            let lastColumn: IColumnDefinition;

            for (let i = 0; i < l; ++i) {
                const column = srcRow.flexibleColumns[i];
                if (column.isHiddenNow) continue;

                flexible = Math.round((toAllocate * column.factor) / srcRow.factorSum);
                toAllocateLeft -= flexible;

                row.widths[row.columnMap[column.id]] = flexible + column.width;
                lastColumn = column;
            }

            if (toAllocateLeft && lastColumn) {
                row.widths[row.columnMap[lastColumn.id]] += toAllocateLeft;
            }
        });
    }
}
