import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    inject
} from "@angular/core";

/*
  Possible extensions:
  - allow other than text data
  - allow providing animation (through class, or just (activate) output?)
*/

@Directive({
    standalone: true,
    selector: "[lgPasteHandler]"
})
/**
 * Implement possibility to handle paste event on any element (focusable, or containing focusable children). When the
 * event is triggered, the given callback will obtain the text (if any) and indicate, whether the event should be
 * consumed or not.
 * Because the general paste event won't be triggered on IE, we explicitly handle ctrl+v there (and only this
 * combination)
 */
export class LgPasteHandlerDirective implements OnChanges, OnDestroy {
    private _elementRef = inject(ElementRef<Node>);

    /**
     * Callback for handling the value to be pasted from the clipboard.
     */
    @Input("lgPasteHandler") pasteValue: null | undefined | ((text: string) => boolean);

    private _attached = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pasteValue) {
            this._bindHandlers();
        }
    }

    ngOnDestroy(): void {
        this._detachHandlers();
    }

    private readonly _onPasteBound = (event: ClipboardEvent): void => this._onPaste(event);
    private readonly _onKeyDownBound = (event: KeyboardEvent): void => this._onKeyDown(event);
    private _pasteTarget: HTMLInputElement | null = null;

    private _bindHandlers(): void {
        const active = this.pasteValue != null;
        if (active) {
            this._attachHandlers();
        } else {
            this._detachHandlers();
        }
    }

    private _attachHandlers(): void {
        if (this._attached) return;
        document.addEventListener("paste", this._onPasteBound);
        this._attached = true;
    }

    private _detachHandlers(): void {
        if (!this._attached) return;
        document.removeEventListener("paste", this._onPasteBound);
        this._attached = false;
    }

    // Note: global binding on document level, because binding on non-editable element doesn't seem to
    // work reliably in chrome (only element selected by mouse click reacts, not elements to which we tabbed)
    private _onPaste(event: ClipboardEvent): void {
        if (this._isFocusInside()) {
            let clipboardText: string;
            // Note: this path will trigger on IE too
            if ((<any>window).clipboardData) {
                // IE Hack
                clipboardText = (<any>window).clipboardData.getData("text");
            } else {
                clipboardText = event.clipboardData.getData("text/plain");
            }
            if (clipboardText) {
                if (this.pasteValue(clipboardText)) {
                    event.preventDefault();
                }
            }
        }
    }

    private _onKeyDown(event: KeyboardEvent): void {
        /*
        IE11 won't trigger paste event on non-editable element. So what we do is
        - detect the ctrl+v combination in the capture phase
        - create temporary input element and give it a focus. Done in time, it will 
          trigger paste event on itself
        - remove the input on next tick
        Hey, Bill Gates made me do it!
        */
        if (event.key === "v" && event.ctrlKey) {
            this._pasteTarget = document.createElement("input");
            this._pasteTarget.type = "text";
            // Note: the input cannot be semantically invisible (css visibility/display),
            // but we can hide it by other means
            this._pasteTarget.style.position = "absolute";
            this._pasteTarget.style.overflow = "hidden";
            this._pasteTarget.style.width = "0px";
            this._pasteTarget.style.height = "0px";
            this._pasteTarget.style.border = "none";
            this._pasteTarget.style.opacity = "0";
            // We add it to parent instead of top document si that we don't have issues with focus traps in dialogs
            this._elementRef.nativeElement.parentNode.appendChild(this._pasteTarget);
            const oldFocus = document.activeElement as HTMLElement;
            this._pasteTarget.focus();
            setTimeout(() => {
                this._elementRef.nativeElement.parentNode?.removeChild(this._pasteTarget);
                this._pasteTarget = null;
                oldFocus?.focus?.();
            }, 0);
        }
    }

    private _isFocusInside(): boolean {
        let current = document.activeElement;
        // Special handling for the IE11 hack
        if (this._pasteTarget && current === this._pasteTarget) return true;
        while (current) {
            if (current === this._elementRef.nativeElement) return true;
            current = current.parentElement;
        }
        return false;
    }
}
