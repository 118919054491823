import { Directive, OnChanges, OnInit, SimpleChanges, Input, HostBinding } from "@angular/core";

enum ColoredType {
    Both,
    NegativesOnly
}

interface IState {
    type: ColoredType;
    isPositive: boolean;
    isNegative: boolean;
    text: string;
}

const state = (
    type: ColoredType = ColoredType.Both,
    isPositive = false,
    isNegative = false,
    text = ""
): IState => ({ type, isPositive, isNegative, text });

@Directive({
    standalone: true,
    selector: "[lgColoredNumber], [lgNegativeColoredNumber]"
})
export class LgColoredNumberDirective implements OnInit, OnChanges {
    @Input("lgColoredNumber") inputValue?: number | string;
    @Input("lgNegativeColoredNumber") inputValueNegativeOnly?: number | string;
    @Input("lgColoredNumberInvert") invert = false;

    @HostBinding("class.positive-number") positiveNumber = false;
    @HostBinding("class.negative-number") negativeNumber = false;
    @HostBinding("innerHTML") text: string;

    private _initialized: boolean;
    private _type: ColoredType;

    ngOnInit(): void {
        this._initialized = false;

        this._mapInputToState();

        this._initialized = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this._initialized || "inputValue" in changes || "invert" in changes) {
            this._mapInputToState();
        }
    }

    private _mapInputToState(): void {
        this._setHostState(this._getStateFromInput());
    }

    private _setHostState({ type, isPositive, isNegative, text }: IState): void {
        if (type === ColoredType.Both) {
            this.positiveNumber = isPositive;
        }
        this.negativeNumber = isNegative;
        this.text = text;
    }

    private _getStateFromInput(): IState {
        const type = this.inputValue != null ? ColoredType.Both : ColoredType.NegativesOnly;

        if (
            (type === ColoredType.Both && this.inputValue == null) ||
            (type === ColoredType.NegativesOnly && this.inputValueNegativeOnly == null)
        ) {
            return state();
        }

        let value =
            "" + (type === ColoredType.Both ? this.inputValue : this.inputValueNegativeOnly);
        const originalValue = value;
        value = value.replace(/[^\d.,-]*/g, "");
        const pos = value.indexOf("-");
        let negative =
            pos !== -1 && pos < value.length - 1 && value[pos + 1] >= "0" && value[pos + 1] <= "9";
        const nonZero = /[1-9]/.test(value);
        if (this.invert) {
            negative = !negative;
        }

        return state(type, nonZero && !negative, nonZero && negative, originalValue);
    }
}
