import { Component, OnInit, Input, OnChanges, OnDestroy, inject } from "@angular/core";
import { Subscription } from "rxjs";

import { LgSimpleChanges } from "@logex/framework/types";

import { MiComparisonSourceService } from "./mi-comparison-source.service";
import { LgSourcesBeingCompared } from "./lg-comparison-source.types";

type ComparingSymbol = "" | "=" | "1" | "2";

@Component({
    selector: "lg-comparison-source-indicator",
    template: `
        <span *ngIf="_isUsedForComparison" class="lg-comparison-source-indicator__circle">
            {{ _comparisonSymbol }}
        </span>
    `,
    host: {
        class: "lg-comparison-source-indicator"
    }
})
export class LgComparisonSourceIndicatorComponent implements OnInit, OnChanges, OnDestroy {
    private _service = inject(MiComparisonSourceService);
    @Input({ required: true }) id!: string;
    @Input({ required: true }) name!: string;
    @Input() order?: number;
    @Input() scope = "default";
    @Input() register = true;

    _isUsedForComparison: boolean;
    _comparisonSymbol: ComparingSymbol;

    private _initialized = false;
    private _subscription: Subscription;

    ngOnInit(): void {
        if (this.register) {
            this._service.registerSource({
                id: this.id,
                name: this.name,
                order: this.order,
                scope: this.scope
            });
        }

        this._subscription = this._service.sourcesBeingCompared$.subscribe(comparing => {
            this._isUsedForComparison = this._isActive(comparing);
            this._comparisonSymbol = this._getSymbol(comparing);
        });

        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgComparisonSourceIndicatorComponent>): void {
        if (!this._initialized) return;

        if (changes.name) {
            this._service.updateSourceName(this.id, this.name, this.scope);
        }
    }

    ngOnDestroy(): void {
        this._service.unregisterSource(this.id, this.scope);
        this._subscription.unsubscribe();
    }

    private _isActive(comparing: LgSourcesBeingCompared): boolean {
        return comparing.firstId === this.id || comparing.secondId === this.id;
    }

    private _getSymbol(comparing: LgSourcesBeingCompared): ComparingSymbol {
        const isFirst = comparing.firstId === this.id;
        const isSecond = comparing.secondId === this.id;

        switch (true) {
            case isFirst && isSecond:
                return "=";
            case isFirst:
                return "1";
            case isSecond:
                return "2";
            default:
                return "";
        }
    }
}
