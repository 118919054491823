const isMsie = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;

/** Copy the specified text to clipboard.
 *
 * @returns promise that resolves to true after the value was copied (or false if it was empty)
 */
export function copyTextToClipboard(text: string | null): Promise<boolean> {
    return new Promise<boolean>(resolve => {
        if (!text) {
            resolve(false);
            return;
        }

        let aux: HTMLElement | null = null;
        let activeElement: HTMLElement | null = null;
        if (isMsie) {
            activeElement = document.activeElement as HTMLElement;
            aux = document.createElement("div");
            aux.innerHTML = "!";
            document.body.appendChild(aux);
            window.getSelection().selectAllChildren(aux);
        }

        const onCopy = (e: ClipboardEvent): void => {
            try {
                if (isMsie) {
                    const clipboardData = (<any>window).clipboardData;
                    clipboardData.setData("text", text);
                    activeElement?.focus?.();
                } else {
                    const clipboardData = e.clipboardData;
                    clipboardData.setData("text/plain", text);
                }
                e.preventDefault();
            } finally {
                if (aux) document.body.removeChild(aux);
                document.removeEventListener("copy", onCopy);
                resolve(true);
            }
        };

        document.addEventListener("copy", onCopy);
        document.execCommand("copy");
    });
}
