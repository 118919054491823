import { inject, Injectable } from "@angular/core";
import _ from "lodash";
import { Subject } from "rxjs";

import { ExceptionsService } from "./exceptions-service";
import { LgConsoleConfiguration, ConsoleLogLevel } from "./lg-console-configuration";
import { ErrorType } from "./lg-error-handler";

/* TODO:
- establish global event source (another service?)
*/

// temporary extension, until we move to TS 2.0 everywhere (these are function signatures added in the latest lib.d.ts)
export interface LgConsole {
    trace(message?: any, ...optionalParams: any[]): void;
    table(...data: any[]): void;
}
interface IGlobalConsole extends LgConsole {}
interface IGroupInfo {
    name: string;
    collapsed: boolean;
    called: boolean;
}

export interface IConsoleEvent {
    source: string;
    name: string;
    args: any[];
}

declare interface ConsoleExtension extends Console {
    profile(label?: string): void;
    profileEnd(label?: string): void;
}

// eslint-disable-next-line no-var
declare var console: ConsoleExtension;

/**
 * Component *lgConsole* in module *logex.services*.
 * Wraps browser console object. If console doesn't have some method, it is not called.
 *
 * ##Usage
 *
 * In start.ts:
 * ```typescript
 * Logex.Application.init(
 *    angular.module( "costApp", [
 *        "logex.application", "costApp.globalControllers", "costmodel.definitions", "ngFileUpload"
 *    ] ),
 *    ["$routeProvider", "app.navigationProvider", "lgConsoleConfig",
 *    ( $routeProvider, appNavigation, lgConsoleConfig: Logex.ConsoleConfiguration ) => {
 * 		appNavigation.registerRoutes( $routeProvider );
 *
 * 		// Configure log levels
 * 		lgConsoleConfig
 * 			.rootLogger( Logex.ConsoleLogLevel.Warn )
 *			.logger( "CostModel", Logex.ConsoleLogLevel.All );
 * 	}] );
 * ```
 *
 * If you use [[Logex.ControllerBase]] as a base class, then you can define the logger name with [[Logex.ControllerBase.getLogSourceName]] template method:
 * ```typescript
 * protected getLogSourceName() {
 * 	return "CostModel.ClusteringDepartments.ClusteringDepartmentsController";
 * }
 * ```
 *
 * Otherwise, at the beginning of your component's constructor add (assuming that "lgConsole" is injected as "console" member):
 * ```typescript
 * this.console = this.console.withSource( "..." );
 * ```
 *
 * When any call on *lgConsole* component is made and this call is not fobidden by the loggin configuration,
 * the component also notifies about it by emitting "lgConsole.onLog" event on the root scope.
 * One could subscribe to such events using helper constant for the event name:
 * ```typescript
 * $rootScope.$on( Logex.Console.ON_LOG_EVENT, ( event, args ) => { ... } );
 * ```
 * N.B. Be careful with $rootScope.$on - always unsubscibe or use onRootScope helper. Otherwise it could create memory leaks.
 */
@Injectable({ providedIn: "root" })
export class LgConsole implements IGlobalConsole {
    private _configuration = inject(LgConsoleConfiguration);
    private _exceptionsService = inject(ExceptionsService);

    public $logEvent = new Subject<IConsoleEvent>();

    // ----------------------------------------------------------------------------------
    // Fields
    private _source = "";
    private _logLevel: ConsoleLogLevel;
    private _groupsStack: IGroupInfo[] = [];

    // ----------------------------------------------------------------------------------
    // Logger configuration

    /**
     * Returns a new instance of Console object with "source" set.
     * Source is a name of a component which is the logging source, or could be also considered as a logger name.
     *
     * @param source Logging source name. Empty string - root-level logger.
     */
    withSource(source: string): LgConsole {
        const newConsole = new LgConsole();
        newConsole._source = source || "";
        newConsole._logLevel = this._configuration.getLogLevel(source);
        return newConsole;
    }

    /**
     * Gets current source.
     */
    source(): string {
        return this._source || "";
    }

    /**
     * Gets current configuration.
     */
    configuration(): LgConsoleConfiguration {
        return this._configuration;
    }

    // ----------------------------------------------------------------------------------
    //

    /**
     * Gets the log level configured for this console's source.
     */
    private _getLogLevel(): ConsoleLogLevel {
        if (this._logLevel !== undefined) return this._logLevel;

        this._logLevel = this._configuration.getLogLevel(this._source);
        return this._logLevel;
    }

    /**
     * Checks if certain log level is enabled.
     *
     * @param desiredLevel
     */
    private _isLevelEnabled(desiredLevel: ConsoleLogLevel): boolean {
        return desiredLevel >= this._getLogLevel();
    }

    private _notify(methodName: ErrorType, ...args: any[]): void {
        this._exceptionsService.add({ source: this._source, methodName, args });
        // this.$logEvent.next( {
        //     source: this._source,
        //     name: methodName,
        //     args: args
        // })
    }

    // ----------------------------------------------------------------------------------
    //

    /**
     * Returns true if Perf level is enabled for this console.
     */
    isPerf(): boolean {
        return this._isLevelEnabled(ConsoleLogLevel.Perf);
    }

    /**
     * Returns true if Debug level is enabled for this console.
     */
    isDebug(): boolean {
        return this._isLevelEnabled(ConsoleLogLevel.Debug);
    }

    /**
     * Returns true if Info level is enabled for this console.
     */
    isInfo(): boolean {
        return this._isLevelEnabled(ConsoleLogLevel.Info);
    }

    /**
     * Returns true if Warn level is enabled for this console.
     */
    isWarn(): boolean {
        return this._isLevelEnabled(ConsoleLogLevel.Warn);
    }

    /**
     * Returns true if Error level is enabled for this console.
     */
    isError(): boolean {
        return this._isLevelEnabled(ConsoleLogLevel.Error);
    }

    // ----------------------------------------------------------------------------------
    // Logging methods of different severity levels

    // Perf

    time(timerName?: string): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Perf)) return;

        this._outputQueuedGroups();
        if (console.time) {
            console.time(this._formatMessage(timerName));
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("time", timerName);
    }

    timeEnd(timerName?: string): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Perf)) return;

        this._outputQueuedGroups();
        if (console.timeEnd) {
            console.timeEnd(this._formatMessage(timerName));
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("timeEnd", timerName);
    }

    perf(message?: string, ...optionalParams: any[]): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Perf)) return;

        this._outputQueuedGroups();
        if (console.log) {
            console.log(this._formatMessage(message), ...optionalParams);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("perf", message, ...optionalParams);
    }

    // Debug

    debug(message?: string, ...optionalParams: any[]): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Debug)) return;

        this._outputQueuedGroups();
        if (console.debug) {
            console.debug(this._formatMessage(message), ...optionalParams);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("debug", message, ...optionalParams);
    }

    assert(test?: boolean, message?: string, ...optionalParams: any[]): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Debug)) return;

        this._outputQueuedGroups();
        if (console.assert) {
            console.assert(test, this._formatMessage(message), ...optionalParams);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("assert", test, message, ...optionalParams);
    }

    count(countTitle?: string): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Debug)) return;

        this._outputQueuedGroups();
        if (console.count) {
            console.count(this._formatMessage(countTitle));
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("count", countTitle);
    }

    dir(value?: any, ...optionalParams: any[]): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Debug)) return;

        this._outputQueuedGroups();
        if (console.dir) {
            console.dir(value, ...optionalParams);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("dir", value, ...optionalParams);
    }

    dirxml(value: any): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Debug)) return;

        this._outputQueuedGroups();
        if (console.dirxml) {
            console.dirxml(value);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("dirxml", value);
    }

    trace(message?: any, ...optionalParams: any[]): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Debug)) return;

        this._outputQueuedGroups();
        if (console.trace) console.trace(this._formatMessage(message), ...optionalParams);
        this._notify("trace", message, ...optionalParams);
    }

    // Info

    info(message?: any, ...optionalParams: any[]): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Info)) return;

        this._outputQueuedGroups();
        if (console.info) {
            console.info(this._formatMessage(message), ...optionalParams);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("info", message, ...optionalParams);
    }

    // Warn

    warn(message?: any, ...optionalParams: any[]): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Warn)) return;

        this._outputQueuedGroups();
        if (console.warn) {
            console.warn(this._formatMessage(message), ...optionalParams);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("warn", message, ...optionalParams);
    }

    // Error

    error(message?: any, ...optionalParams: any[]): void {
        if (!this._isLevelEnabled(ConsoleLogLevel.Error)) return;

        this._outputQueuedGroups();
        if (console.error) {
            console.error(this._formatMessage(message), ...optionalParams);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("error", message, ...optionalParams);
    }

    // Unconditional

    clear(): void {
        if (console.clear) console.clear();
        this._notify("clear");
    }

    log(message?: any, ...optionalParams: any[]): void {
        this._outputQueuedGroups();

        if (console.log) {
            console.log(this._formatMessage(message), ...optionalParams);
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("log", message, ...optionalParams);
    }

    group(groupTitle?: string): void {
        const enabled = this._isLevelEnabled(ConsoleLogLevel.All);
        this._groupsStack.push({
            name: groupTitle,
            collapsed: false,
            called: enabled
        });

        if (enabled) {
            if (console.group) {
                console.group(this._formatMessage(groupTitle));
                if (this._configuration.outputTrace) if (console.trace) console.trace();
            }
            this._notify("group", groupTitle);
        }
    }

    groupCollapsed(groupTitle?: string): void {
        const enabled = this._isLevelEnabled(ConsoleLogLevel.All);
        this._groupsStack.push({
            name: groupTitle,
            collapsed: true,
            called: enabled
        });

        if (enabled) {
            if (console.groupCollapsed) {
                console.groupCollapsed(this._formatMessage(groupTitle));
                if (this._configuration.outputTrace) if (console.trace) console.trace();
            }
            this._notify("groupCollapsed", groupTitle);
        }
    }

    groupEnd(): void {
        if (this._groupsStack.length > 0) {
            const groupInfo = this._groupsStack.pop();
            if (groupInfo.called) {
                if (console.groupEnd) console.groupEnd();
                this._notify("groupEnd");
            }
        }
    }

    /**
     * Output groups that have been queued by calls to "group" and "groupCollapsed"
     * but were not called to console yet, because it according to log level they
     * could be empty.
     */
    private _outputQueuedGroups(): void {
        _.each(this._groupsStack, x => {
            if (!x.called) {
                if (!x.collapsed) {
                    if (console.group) {
                        console.group(this._formatMessage(x.name));
                        if (this._configuration.outputTrace) if (console.trace) console.trace();
                    }
                    this._notify("group", x.name);
                } else {
                    if (console.groupCollapsed) {
                        console.groupCollapsed(this._formatMessage(x.name));
                        if (this._configuration.outputTrace) if (console.trace) console.trace();
                    }
                    this._notify("groupCollapsed", x.name);
                }
                x.called = true;
            }
        });
    }

    profile(reportName?: string): void {
        if (console.profile) {
            console.profile(this._formatMessage(reportName));
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("profile", reportName);
    }

    profileEnd(): void {
        if (console.profileEnd) {
            console.profileEnd();
            if (this._configuration.outputTrace) if (console.trace) console.trace();
        }
        this._notify("profileEnd");
    }

    table(...data: any[]): void {
        if (console.table) {
            console.table(...data);
        }
        this._notify("table", ...data);
    }

    private _formatMessage(message: string): string {
        if (!this._source) return message;

        return this._source + ": " + message;
    }
}
