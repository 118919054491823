import _ from "lodash";
import { Injectable, inject } from "@angular/core";

import { LgDialogRef } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import {
    LgMultilevelPickerDialog,
    IMultilevelPickerConfiguration,
    IMultilevelPickerAssignment
} from "../multilevel-picker/index";
import { IMultilevelCopyPasteSelection } from "../multilevel-picker/lg-multilevel-picker-dialog.types";
import { IPasteButtonColumnInfo, IDataCell } from "../copy-paste/copy-paste.types";
import {
    IAvailableProduct,
    IProductPickerDialogConfiguration,
    IProductPickerDialogStandardConfiguration,
    ICareProduct,
    IStandardCareProduct
} from "./lg-product-picker-dialog.types";

@Injectable({ providedIn: "root" })
export class LgProductPickerDialog<T extends number | string> {
    private _lgTranslate = inject(LgTranslateService);
    private _picker = inject(LgMultilevelPickerDialog<any, any>);

    private _translate: (id: string) => string;
    private _namespace = "FW._Dialogs._ProductPickerDialog";

    constructor() {
        this._translate = id => this._lgTranslate.translate(this._namespace + id);
    }

    // -------------------------------------------------------------------------------------------------------------------
    //  Pick insured products (i.e. a pair of product code and insured flag )
    pickInsuredProducts(
        configuration: IProductPickerDialogConfiguration<T>,
        readonly: boolean,
        availableProducts: Array<IAvailableProduct<T>>,
        otherAssignment: IMultilevelPickerAssignment,
        products: Array<IAvailableProduct<T>>,
        parentDialog?: LgDialogRef<any>
    ): Promise<Array<IAvailableProduct<T>>> {
        const fullConfig = this._createPickerConfiguration(
            configuration,
            item => item.code + "_" + !!item.is_insured,
            ".Paste_declaration_and_product_codes",

            [
                {
                    field: "declarationcode",
                    name: this._translate(".Copy_declaration_code_header"),
                    type: "string",
                    key: true
                },
                {
                    field: "code",
                    name: this._translate(".Copy_product_header"),
                    type: "string",
                    key: true
                }
            ],

            // getCopyPasteValues
            items => {
                return _.map(items, i => ({
                    code: i.definition.is_ovp ? "OVPXXXXXX" : i.item.code,
                    declarationcode: i.item.is_insured
                        ? i.definition.declarationcode
                        : i.definition.uninsured_declarationcode
                }));
            },

            // preprocessPasteValues
            (columns, data) => {
                // The code below solves a typical problem that can arise when user is copy-pasting values from Excel.
                // Excel often tries to turn cell values into numbers which makes it harder for us to match them back to the original codes.
                // This can be a problem when code (think declarationCode) is a string and:
                // a) code contains only digits (i.e. Excel converts it into int/decimal)
                // b) code can contain a single letter and that letter can be 'e' or 'E' (i.e. Excel can treat it as a number in scientific notation)
                //
                // Example:
                // 1. Potential codes are a) "1234" b) "1.234E4"
                // 2. User pastes/types them into Excel which can convert them into a) 1234,00 b) 1.234E+07
                // 3. User pastes those values back into our system and we want to match it back to the original code from point 1
                //
                // Strategy is to convert it to a number using `+`
                //  I. If that fails (NaN) then it's safe to assume that Excel had nothing to break
                // II. Otherwise we assume the worst and convert our string code to a number (`+`) and compare that with the pasted value.
                //     Here we're using the fact that `+` can successfully produce the same number from both "1.234E+07" (Excel) and "1234E4" (our code)

                const declIndex = _.findIndex(columns, m => m.field === "declarationcode");
                const codeIndex = _.findIndex(columns, m => m.field === "code");
                for (const row of data) {
                    const declCodeAsNumber = +row[declIndex].rawValue.replace(",", ".");

                    if (isNaN(declCodeAsNumber) || !isFinite(declCodeAsNumber)) {
                        // I. (Excel couldn't have done anything bad)
                        // we don't need to normalize declaration code, but we need to convert zp to number (otherwise that's done lower in the code)
                        // since the declaration code was not numeric, we know it cannot be OVP
                        row[codeIndex].value = +row[codeIndex].value;
                        continue;
                    }

                    let asOvp = false;
                    let sourceZp = +row[codeIndex].value;
                    if (row[codeIndex].value === "OVPXXXXXX") {
                        sourceZp = +row[declIndex].value;
                        asOvp = true;
                    }

                    const def = configuration.productsDefinition[sourceZp];
                    if (!def || (asOvp && !def.is_ovp)) continue; // invalid

                    // II.
                    if (declCodeAsNumber === +def.declarationcode) {
                        row[declIndex].rawValue = row[declIndex].value = def.declarationcode;
                    } else if (declCodeAsNumber === +def.uninsured_declarationcode) {
                        row[declIndex].rawValue = row[declIndex].value =
                            def.uninsured_declarationcode;
                    }

                    if (def.is_ovp) {
                        row[codeIndex].rawValue = row[codeIndex].value = "OVPXXXXXX";
                    } else {
                        row[codeIndex].value = def.code;
                    }
                }
            },

            // convertPasteToSelectionKeys
            newValues => {
                const selection: string[] = [];
                _.each(newValues as Array<{ code: number; declarationcode: string }>, p => {
                    let asOvp = false;
                    if (p.code.toString() === "OVPXXXXXX") {
                        asOvp = true;
                        p.code = +p.declarationcode;
                    }
                    const def = configuration.productsDefinition[p.code];
                    if (!def || (asOvp && !def.is_ovp)) {
                        selection.push(null);
                        return;
                    }
                    let isInsured: boolean | null = null;
                    if (def.declarationcode === p.declarationcode) {
                        isInsured = true;
                    } else if (
                        def.uninsured_declarationcode &&
                        def.uninsured_declarationcode === p.declarationcode
                    ) {
                        isInsured = false;
                    }
                    selection.push(p.code + "_" + !!isInsured);
                });
                return selection;
            }
        );

        return this._picker
            .pickItems(
                fullConfig,
                readonly,
                availableProducts,
                otherAssignment,
                products,
                parentDialog
            )
            .then(items => {
                return items;
            });
    }

    pickInsuredProductsStandard(
        configuration: IProductPickerDialogStandardConfiguration<T>,
        readonly: boolean,
        availableProducts: Array<IAvailableProduct<T>>,
        otherAssignment: IMultilevelPickerAssignment,
        products: Array<IAvailableProduct<T>>,
        parentDialog?: LgDialogRef<any>
    ): Promise<Array<IAvailableProduct<T>>> {
        return this.pickInsuredProducts(
            this._getStandardGroupingConfiguration(configuration),
            readonly,
            availableProducts,
            otherAssignment,
            products,
            parentDialog
        );
    }

    // -------------------------------------------------------------------------------------------------------------------
    //  Pick products (i.e. just product codes)
    pickProducts(
        configuration: IProductPickerDialogConfiguration<T>,
        readonly: boolean,
        availableProducts: Array<IAvailableProduct<T>>,
        otherAssignment: IMultilevelPickerAssignment,
        products: T[],
        parentDialog?: LgDialogRef<any>
    ): Promise<T[]> {
        const selection: Array<IAvailableProduct<T>> = [];
        const lookup = _.keyBy(
            _.filter(availableProducts, p => !p.is_insured),
            p => p.code
        );

        // convert the product codes to IAvailableProduct entries
        _.each(products, p => {
            selection.push({ code: p, is_insured: true });
            const uninsuredSource = lookup[p];
            if (uninsuredSource) {
                selection.push({ code: p, is_insured: false });
            }
        });

        const fullConfig = this._createPickerConfiguration(
            configuration,
            item => "" + item.code,
            ".Paste_product_codes",
            [
                {
                    field: "code",
                    name: this._translate(".Copy_product_header"),
                    type: "number",
                    key: true
                }
            ],

            // getCopyPasteValues
            items => {
                return _(items)
                    .map(p => p.item.code)
                    .uniq()
                    .map(p => ({ code: p }))
                    .value();
            },

            null, // no preprocessing

            // convertPasteToSelectionKeys
            newValues => {
                return _.map(newValues as Array<{ code: number }>, p => "" + p.code);
            }
        );

        return this._picker
            .pickItems(
                fullConfig,
                readonly,
                availableProducts,
                otherAssignment,
                selection,
                parentDialog
            )
            .then(items => {
                return _(items)
                    .map(p => p.code)
                    .uniq()
                    .value();
            });
    }

    pickProductsStandard(
        configuration: IProductPickerDialogStandardConfiguration<T>,
        readonly: boolean,
        availableProducts: Array<IAvailableProduct<T>>,
        otherAssignment: IMultilevelPickerAssignment,
        products: T[],
        parentDialog?: LgDialogRef<any>
    ): Promise<T[]> {
        return this.pickProducts(
            this._getStandardGroupingConfiguration(configuration),
            readonly,
            availableProducts,
            otherAssignment,
            products,
            parentDialog
        );
    }

    // -------------------------------------------------------------------------------------------------------------------
    //  Pick declaration codes
    pickDeclarationCodes(
        configuration: IProductPickerDialogConfiguration<T>,
        readonly: boolean,
        availableProducts: Array<IAvailableProduct<T>>,
        otherAssignment: IMultilevelPickerAssignment,
        products: string[],
        parentDialog?: LgDialogRef<any>
    ): Promise<string[] | boolean[]> {
        const getDeclarationCode = (item: IAvailableProduct<T>): string =>
            item.is_insured
                ? configuration.productsDefinition["" + item.code].declarationcode
                : configuration.productsDefinition["" + item.code].uninsured_declarationcode;

        const lookup: _.Dictionary<Array<IAvailableProduct<T>>> = {};
        _.each(configuration.productsDefinition, def => {
            if (def.declarationcode) {
                let list = lookup[def.declarationcode];
                if (!list) list = lookup[def.declarationcode] = [];
                list.push({ code: def.code, is_insured: true });
            }
            if (def.uninsured_declarationcode) {
                let list = lookup[def.uninsured_declarationcode];
                if (!list) list = lookup[def.uninsured_declarationcode] = [];
                list.push({ code: def.code, is_insured: false });
            }
        });

        const selection: Array<IAvailableProduct<T>> = [];
        _.each(products, p => {
            const def = lookup[p];
            if (!def) {
                selection.push({ code: null, is_insured: false }); // let it fail
                return;
            }
            for (const entry of def) {
                selection.push(entry);
            }
        });

        const fullConfig = this._createPickerConfiguration(
            configuration,
            getDeclarationCode,
            ".Paste_declaration_codes",

            [
                {
                    field: "declarationcode",
                    name: this._translate(".Copy_declaration_code_header"),
                    type: "string",
                    key: true
                }
            ],

            // getCopyPasteValues
            items => {
                return _(items)
                    .map(i =>
                        i.item.is_insured
                            ? i.definition.declarationcode
                            : i.definition.uninsured_declarationcode
                    )
                    .uniq()
                    .map(d => ({ declarationcode: d }))
                    .value();
            },

            // preprocessPasteValues
            (columns, data) => {
                // since we won't have the zorgproduct id to help us, prepare lookup of all possible declaration codes re-interpeted as number
                const maimedLookup: _.Dictionary<string> = {};
                _.each(configuration.productsDefinition, def => {
                    let codeAsNumber = +def.declarationcode;
                    if (!isNaN(codeAsNumber) && isFinite(codeAsNumber)) {
                        maimedLookup["" + codeAsNumber] = def.declarationcode;
                    }
                    codeAsNumber = +def.uninsured_declarationcode;
                    if (
                        def.uninsured_declarationcode &&
                        !isNaN(codeAsNumber) &&
                        isFinite(codeAsNumber)
                    ) {
                        maimedLookup["" + codeAsNumber] = def.uninsured_declarationcode;
                    }
                });

                const declIndex = _.findIndex(columns, m => m.field === "declarationcode");
                for (const row of data) {
                    // test if the declaration code could be a number
                    const codeAsNumber = +row[declIndex].rawValue.replace(",", ".");
                    if (isNaN(codeAsNumber) || !isFinite(codeAsNumber)) continue;

                    const translated = maimedLookup["" + codeAsNumber];
                    if (translated) {
                        row[declIndex].rawValue = row[declIndex].value = translated;
                    }
                }
            },

            // convertPasteToSelectionKeys
            newValues => {
                return _.map(
                    newValues as Array<{ declarationcode: string }>,
                    p => "" + p.declarationcode
                );
            }
        );

        return this._picker
            .pickItems(
                fullConfig,
                readonly,
                availableProducts,
                otherAssignment,
                selection,
                parentDialog
            )
            .then(items => {
                const result = _(items).map(getDeclarationCode).uniq().value();
                return result;
            });
    }

    pickDeclarationCodesStandard(
        configuration: IProductPickerDialogStandardConfiguration<T>,
        readonly: boolean,
        availableProducts: Array<IAvailableProduct<T>>,
        otherAssignment: IMultilevelPickerAssignment,
        products: string[],
        parentDialog?: LgDialogRef<any>
    ): Promise<string[]> {
        return this.pickDeclarationCodes(
            this._getStandardGroupingConfiguration(configuration),
            readonly,
            availableProducts,
            otherAssignment,
            products,
            parentDialog
        ) as any;
    }

    // -------------------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------------------
    //  Fill shared part of the multi-level picker configuration
    private _createPickerConfiguration(
        configuration: IProductPickerDialogConfiguration<T>,
        getSelectionKey: (item: IAvailableProduct<T>) => string,
        pasteExtraInfoLc: string,
        copyPasteDefinition: IPasteButtonColumnInfo[],
        getCopyPasteValues: (
            items: Array<IMultilevelCopyPasteSelection<IAvailableProduct<T>, ICareProduct<T>>>
        ) => object[],
        preprocessPasteValues:
            | null
            | ((columns: IPasteButtonColumnInfo[], data: IDataCell[][]) => void),
        convertPasteToSelectionKeys: (newValues: any[]) => string[]
    ): IMultilevelPickerConfiguration<IAvailableProduct<T>, ICareProduct<T>> {
        const fullConfig: IMultilevelPickerConfiguration<IAvailableProduct<T>, ICareProduct<T>> = {
            allowBlocked: configuration.allowBlocked,
            alreadyUsedIcon: configuration.alreadyUsedIcon,
            disableCopyPaste: configuration.disableCopyPaste,
            groups: configuration.groups,
            groupIds: configuration.groupIds,
            localizationPrefix: configuration.localizationPrefix,
            getDefinitionId: item => item.code,
            items: configuration.productsDefinition,
            getItemName: (product, definition) => this._getProductName(product, definition),
            getSelectionKey,
            getUniqueKey: item => item.code + "_" + !!item.is_insured,
            sortBy: [
                (item, _definition) => item.code,
                (item, definition) =>
                    item.is_insured
                        ? definition.declarationcode
                        : definition.uninsured_declarationcode
            ],
            copyPasteDefinition,
            getCopyPasteValues,
            preprocessPasteValues,
            convertPasteToSelectionKeys,
            pasteExtraInfoLc: this._namespace + pasteExtraInfoLc
        };
        return fullConfig;
    }

    // -------------------------------------------------------------------------------------------------------------------
    //  Product name
    private _getProductName(product: IAvailableProduct<T>, definition: ICareProduct<T>): string {
        let declarationcode =
            (product.is_insured
                ? definition.declarationcode
                : definition.uninsured_declarationcode) || "UITVAL";
        if (declarationcode.length === 5) declarationcode = "0" + declarationcode;
        return `${declarationcode} | ${definition.code} - ${definition.name}`;
    }

    // -------------------------------------------------------------------------------------------------------------------
    //  Create configuration for the standard grouping (tariff type + product group )
    private _getStandardGroupingConfiguration(
        configuration: IProductPickerDialogStandardConfiguration<T>
    ): IProductPickerDialogConfiguration<any> {
        const unknownGroup = this._translate(".Unknown_zorgproductgroup");
        const uknownTariff = this._translate(".Unknown_tarieftype");
        const localizationPrefix =
            configuration.localizationPrefix || this._namespace + "._Customization";

        return {
            groups: [
                {
                    source: configuration.tariffTypesDefinition,
                    getName: t => (t ? t.name : uknownTariff),
                    sortBy: (_t, id) => id,
                    isUsedLc: ".Tariff_type_all_used",
                    isPartiallyUsedLc: ".Tariff_type_some_used_elsewhere",
                    labelLc: this._namespace + ".Tariff_type_select_label",
                    filterPlaceholderLc: this._namespace + ".Tariff_types_placeholder",
                    expandedByDefault: true
                },
                {
                    source: configuration.careProductGroupsDefinition,
                    getName: (t, id) => `${id} - ${t ? t.name : unknownGroup}`,
                    sortBy: (_t, id) => id,
                    isUsedLc: ".Product_group_all_used",
                    isPartiallyUsedLc: ".Product_group_some_used_elsewhere",
                    labelLc: this._namespace + ".Product_group_select_label",
                    filterPlaceholderLc: this._namespace + ".Product_groups_placeholder",
                    expandedByDefault: true
                }
            ],
            groupIds: [
                (product, definition: Partial<IStandardCareProduct<T>>) =>
                    product.is_insured || !configuration.uninsuredTariffType
                        ? definition.tarifftype_code
                        : definition.uninsured_tarifftype_code,
                (_product, definition: Partial<IStandardCareProduct<T>>) => definition.group_code
            ],
            localizationPrefix,
            ...configuration
        };
    }
}
