import {
    Directive,
    Input,
    Renderer2,
    ElementRef,
    OnChanges,
    OnDestroy,
    inject
} from "@angular/core";
import { Subscription } from "rxjs";

import { toInteger } from "@logex/framework/utilities";
import { LgColRowDirective } from "./lg-col-row.directive";

@Directive({
    standalone: true,
    selector: "[lgColWidth]"
})
export class LgColWidthDirective implements OnChanges, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);
    private _row = inject(LgColRowDirective);

    @Input("lgColWidth") id: string;

    @Input("lgColSpan") set colSpan(val: number | string) {
        this._colSpan = toInteger(val);
    }

    get colSpan(): number {
        return this._colSpan;
    }

    // ---------------------------------------------------------------------------------------------
    private _colSpan = 1;
    private _subscription: Subscription;

    constructor() {
        this._subscription = this._row.onChange().subscribe(() => {
            this._reassign();
        });
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnChanges(): void {
        this._reassign();
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    // ---------------------------------------------------------------------------------------------
    private _reassign(): void {
        if (!this.id) return;

        const params = this._row.getColumnParameters(this.id, this._colSpan);

        if (params) {
            /*
            if ( this._sortByCol !== null ) {
                params.width -= params.last ? 14 : ( 12 - ( params.paddingRight - 2 ) );
            }
            */

            this._renderer.setStyle(this._elementRef.nativeElement, "width", params.width + "px");
        }
    }
}
