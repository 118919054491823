import _ from "lodash";

/**
 * Expand the data packed by data wrapper's ResultToPackedArray method.
 *
 * @param data packed rows
 * @returns unpacked rows
 */
export function expandPackedRows(data: any[], rename?: _.Dictionary<string>): any[] {
    if (data == null) return data;
    if (!_.isArray(data)) return null;

    const result: any[] = [];
    if (!data[0]) {
        return result;
    }

    let lookup: string[] = data[0];
    if (rename) {
        lookup = _.map(lookup, column => rename[column] || column);
    }

    let previous: any = {};
    const columns = lookup.length;
    for (let i = 1, l = data.length; i < l; ++i) {
        const row = data[i];
        const current: any = {};
        for (let j = 0; j < columns; ++j) {
            const name = lookup[j];
            let val = row[j];
            if (val === "^") val = previous[name];
            current[name] = val;
        }
        result.push(current);
        previous = current;
    }
    return result;
}
