import { InjectionToken } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { Observable } from "rxjs";

import { IFilterExportDefinition } from "@logex/framework/lg-exports";
import { LgFormatTypePipe } from "@logex/framework/ui-core";

import type { IFilterDefinition } from "./filter-definition";
import type { LgFilterSet } from "./lg-filterset";
import type { FilterRendererComponentBase } from "./filter-renderer-component-base";

export const LG_FILTERSET_RENDERER_FACTORIES = new InjectionToken<IFilterRendererFactory>(
    "lgFilterSetRendererFactories"
);

/**
 * Represents interface of the filter renderer. The renderer's responsibility is to maintain the filter's
 * storage (within the provided dictionary), and generate templates for the list view / preview view.
 * The renderer's constructor should also take care of normalizing any additional parameters it
 * needs as part of the definition.
 *
 * The constructor can be injected with the following parameters:
 * definition: the definition of the filter (after the filterset did its part of the normalization)
 * $scope: the scope as passed to the filterset
 * filters: the dictionary storing all the filters (the current filter should be stored under storage from the definition).
 *   This is needed because some filter implementations (like the combo box) assume the filter object
 *   will be replaced in some cases.
 *
 * TODO: consider adding callback that will be used to notify the renderer of filter changes. That would help
 * to optimize the dropdowns, and make it possible to easily implement renderers reacting to current filters
 * immediately (think radios where some choices are disabled). However this would work only for changes triggered
 * by the FilterSet, not externally. Is it worth it?
 */
export interface IFilterRenderer {
    /**
     * Initialize the required storage (if any) inside the filters directionary. The storage should be
     * associated with the definition's storage parameter
     */
    createStorage(): void;

    /**
     * Clear (or empty) the filter.
     *
     * @return true if any change occured
     */
    clear(): boolean;

    /**
     * Return true if the filter is active (that is, is filtering something). This may be called from
     * watches and thus should be fast
     */
    active(): boolean;

    /**
     * Return true if the filter's preview is visible. This will be typically identical to active().
     * This may be called from watches and thus should be fast
     */
    previewVisible(): boolean;

    /**
     * Return the template for one line of the filter block. The template can refer to the following items:
     * - entry is the current definition
     * - set is the current FilterSet
     * - changed() is callback, which should be called when the filter is modified
     */
    getFilterLineComponent(): ComponentType<FilterRendererComponentBase<any, any>>;

    /**
     * Return the template for the filter's popup attached to the preview block. This can be missing if there
     * is no popup (note that this cannot be determined dynamically because we don't want to watch over
     * getPopupTemplate source. But see the implementation of CheckboxFilterRenderer to how it can be
     * determined at the initialization time)
     *
     * The template can refer to the following items:
     * - entry is the current definition
     * - set is the current FilterSet
     * - changed() is callback, which should be called when the filter is modified
     * - clear()  is callback that you can call to clear the filter
     *  */
    getPopupComponent?(): ComponentType<FilterRendererComponentBase<any, any>>;

    /**
     * Returns the name of the filter, as shown in the preview list. If not defined, the definition name
     * will be used.
     */
    getPreviewName?(): string | Observable<string>;

    /**
     * Return the logex-xlsx export definition of the filter.
     */
    getExportDefinition(): IFilterExportDefinition;

    /**
     * Return representation of the filter's state. If serialization is not supported, return null
     */
    serialize(): string;

    /**
     * Apply the serialized state, return true of the state has changed (or if change cannot be determined)
     */
    deserialize(state: string): boolean;
}

export interface IFilterRendererFactory {
    name: string;
    create(
        definition: IFilterDefinition,
        filters: _.Dictionary<any>,
        definitions: IFilterDefinition[],
        filterSet: LgFilterSet,
        formatPipe: LgFormatTypePipe
    ): IFilterRenderer;
}
