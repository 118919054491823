import _ from "lodash";
import {
    Directive,
    Input,
    HostListener,
    ElementRef,
    Renderer2,
    OnDestroy,
    OnInit,
    DoCheck,
    inject
} from "@angular/core";

import { elementHasClass, toBoolean } from "@logex/framework/utilities";
import { MarkElementAsSorted } from "@logex/framework/ui-core";

import { LgPivotTableHeaderDirective } from "./templates/lg-pivot-table-header.directive";

interface IColumn {
    column: string;
    reverse: boolean;
}

@Directive({
    standalone: true,
    selector: "[lgPivotTableSortByColumn],[lgPivotTableSortByColumnIndicator]",
    providers: [{ provide: MarkElementAsSorted, useValue: true }],
    host: {
        "[class.lg-sort-by-column--indicator-click]": "_indicatorOnly",
        "[class.lg-sort-by-column--disabled]": "_sortByDisabled"
    }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class LgPivotTableSortByColumn implements OnInit, DoCheck, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _header = inject(LgPivotTableHeaderDirective);
    private _renderer = inject(Renderer2);

    /**
     * Column name(s) to be sorted by.
     */
    @Input() set lgPivotTableSortByColumn(val: string | string[]) {
        this._indicatorOnly = false;
        this._setColumnNames(val);
    }

    get lgPivotTableSortByColumn(): string | string[] {
        if (this._sortByColumnArray) {
            return this._sortByColumnArray.map(
                (col, i) =>
                    `${
                        (
                            i === 0
                                ? this._sortByDefaultDesc
                                : col.reverse !== this._sortByDefaultDesc
                        )
                            ? "-"
                            : ""
                    }${col.column}`
            );
        } else {
            return `${this._sortByDefaultDesc ? "-" : ""}${this._sortByColumn}`;
        }
    }

    /**
     * Column name(s) to be sorted by.
     */
    @Input() set lgPivotTableSortByColumnIndicator(val: string | string[]) {
        this._indicatorOnly = true;
        this._setColumnNames(val);
    }

    get lgPivotTableSortByColumnIndicator(): string | string[] {
        return this.lgPivotTableSortByColumn;
    }

    /**
     * Default sorting.
     */
    @Input() set sortByDefault(val: string) {
        if (!val) return;
        this._sortByDefaultDesc = this._sortByDefaultDescInput =
            val === "-" || val.toUpperCase() === "DESC";
    }

    get sortByDefault(): string {
        return this._sortByDefaultDesc ? "DESC" : "ASC";
    }

    /**
     * Row level to be sorted.
     */
    @Input() set sortByLevel(value: number | string | undefined | null) {
        if (value === undefined || value === null) {
            this._level = null;
            return;
        }
        this._level = value;
    }

    get sortByLevel(): number | string | null {
        return this._level;
    }

    /**
     * Specifies if sort by disabled items or not.
     *
     * @default true
     */
    @Input() public set sortByDisabled(val: boolean | "true" | "false") {
        this._sortByDisabled = toBoolean(val);
    }

    get sortByDisabled(): boolean {
        return this._sortByDisabled;
    }

    _sortByDisabled = false;
    private _sortByDefaultDesc = false;
    private _sortByDefaultDescInput: boolean | null = null;
    private _sortByColumn: string;
    private _sortByColumnArray: IColumn[] = null;
    private _symbol: HTMLElement;
    private _sub: HTMLElement;
    _indicatorOnly = false;
    private _direction: 1 | -1 | null = null;
    private _level: number | string | null = null;
    private _lastStored: any = null;

    ngOnInit(): void {
        this._symbol = this._renderer.createElement("div");
        this._renderer.addClass(this._symbol, "lg-sort-by-column__indicator");
        this._sub = this._renderer.createElement("div");
        this._renderer.appendChild(this._symbol, this._sub);

        this._renderer.insertBefore(
            this._elementRef.nativeElement,
            this._symbol,
            this._elementRef.nativeElement.firstChild
        );

        this._renderer.addClass(this._elementRef.nativeElement, "lg-sort-by-column");
        this._renderer.listen(this._symbol, "click", event => this._onMouseClick(event));
        this._updateDisplay();
    }

    ngDoCheck(): void {
        this._updateDirection();
    }

    ngOnDestroy(): void {
        this._renderer.removeChild(this._elementRef.nativeElement, this._symbol);
        if (this._renderer.destroyNode) {
            this._renderer.destroyNode(this._sub);
            this._renderer.destroyNode(this._symbol);
        }
        this._symbol = null;
    }

    @HostListener("click", ["$event"])
    _onMouseClick(event: MouseEvent): boolean {
        if (this._sortByDisabled) return true;
        if (
            elementHasClass(this._elementRef, "lg-sort-by-column--disabled") ||
            elementHasClass(
                this._renderer.parentNode(this._elementRef.nativeElement),
                "lg-sort-by-column--disabled"
            )
        )
            return true;

        if (
            this._indicatorOnly &&
            event.target !== this._symbol &&
            this._renderer.parentNode(event.target) !== this._symbol
        ) {
            return true;
        }

        if (this._direction === 1) {
            this._setOrderBy(false);
        } else if (this._direction === -1) {
            this._setOrderBy(true);
        } else {
            this._setOrderBy(!this._sortByDefaultDesc);
        }

        event.stopPropagation();
        return false;
    }

    private _updateDirection(): void {
        let stored = this._header.getOrderBy(
            this._level === null ? this._header.maxVisibleLevel : this._level
        );
        if (stored === this._lastStored) return;

        if (_.isArray(stored)) stored = stored[0];

        if (stored === this._sortByColumn || stored === "+" + this._sortByColumn) {
            this._direction = 1;
        } else if (stored === "-" + this._sortByColumn) {
            this._direction = -1;
        } else {
            this._direction = null;
        }

        this._updateDisplay();
        this._lastStored = stored;
    }

    private _updateDisplay(): void {
        this._updateClasses(this._elementRef.nativeElement);
    }

    private _updateClasses(target: HTMLElement): void {
        if (!target) return;

        if (this._direction === 1) {
            this._renderer.removeClass(target, "lg-sort-by-column--desc");
            this._renderer.addClass(target, "lg-sort-by-column--asc");
        } else if (this._direction === -1) {
            this._renderer.removeClass(target, "lg-sort-by-column--asc");
            this._renderer.addClass(target, "lg-sort-by-column--desc");
        } else {
            this._renderer.removeClass(target, "lg-sort-by-column--desc");
            this._renderer.removeClass(target, "lg-sort-by-column--asc");
        }
    }

    private _setOrderBy(ascending: boolean): void {
        let target: string | string[];
        if (this._sortByColumnArray === null) {
            if (ascending) {
                target = this._sortByColumn;
            } else {
                target = "-" + this._sortByColumn;
            }
        } else {
            target = _.map(this._sortByColumnArray, a =>
                ascending === a.reverse ? "-" + a.column : a.column
            );
        }
        this._header.setOrderBy(
            this._level == null ? this._header.maxVisibleLevel : this._level,
            target
        );
        this._updateDirection();
    }

    private _setColumnNames(val: string | string[]): void {
        if (!val || !val.length) return;

        this._sortByColumnArray = null;

        if (_.isString(val) && val[0] === "[") {
            val = _.map(val.substring(1, val.length - 1).split(","), s => s.trim());
        }

        if (_.isArray(val)) {
            let firstDesc: boolean | null = null;
            this._sortByColumnArray = _.map(val, s => {
                s = s.trim();
                let desc = false;
                if (s[0] === "-") {
                    desc = true;
                    s = s.substring(1);
                }
                if (firstDesc === null) {
                    firstDesc = desc;
                }
                return <IColumn>{
                    column: s,
                    reverse: firstDesc !== desc
                };
            });
            this._sortByColumn = this._sortByColumnArray[0].column;
            this._sortByDefaultDesc = firstDesc;
        } else if (val[0] === "-") {
            this._sortByColumn = val.substring(1);
            this._sortByDefaultDesc = true;
        } else {
            this._sortByColumn = val;
            this._sortByDefaultDesc = false;
        }

        if (this._sortByDefaultDescInput !== null)
            this._sortByDefaultDesc = this._sortByDefaultDescInput; // absolute priority
    }
}
