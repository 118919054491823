import * as _ from "lodash";
import { NgIterable } from "@angular/core";
import { IVirtualForOfCache } from "./lg-virtual-for-of.types";

/**
 * The static cache is used when itemHeight is a number.
 * We can calculate all necessary properties by getting length of iterable and item length
 * When iterable is array, cache is not recalculated and uses only its' length
 * When iterable is not an array, cache is recalculated as there's no easy way to get length.
 */
export class LgVirtualForOfStaticCache<T> implements IVirtualForOfCache<T> {
    private _numberOfItems: number;
    private _itemHeight: number;
    private _source: NgIterable<T>;

    get length(): number {
        return this._numberOfItems;
    }

    constructor(itemHeight: number) {
        this._itemHeight = itemHeight;
    }

    updateCache(source: NgIterable<T>): void {
        this._source = source;
        this._setNumberOfItems();
    }

    private _setNumberOfItems(): void {
        if (_.isArray(this._source)) {
            this._numberOfItems = this._source.length;
            return;
        }
        let numberOfItems = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const item of this._source) numberOfItems++;
        this._numberOfItems = numberOfItems;
    }

    getContentHeight(): number {
        return this.length * this._itemHeight;
    }

    getYPositionByIndex(index: number, isAbove: boolean): number {
        index = isAbove ? index : index + 1;
        return index * this._itemHeight;
    }

    getEnsureVisibleIndex(ensureVisible: number | T): number | undefined {
        if (_.isNumber(ensureVisible)) return ensureVisible;
        let index = 0;
        for (const item of this._source) {
            if (ensureVisible === item) return index;
            index++;
        }
        return undefined;
    }

    getIndexFromPosition(yPosition: number, isAbove: boolean): number {
        const lastIndex = Math.max(0, this._numberOfItems - 1);
        const round = isAbove ? Math.ceil : Math.floor;
        const index = Math.min(lastIndex, round(yPosition / this._itemHeight));
        return isAbove ? index : Math.max(0, index - 1);
    }
}
