<div [lgWorkingOverlay]="_working">
    <div class="form-group">
        <div class="form-row form-row--input">
            <label class="form-row__label" lgTranslate>.Name_label</label>
            <div class="form-row__controls">
                <input
                    type="text"
                    class="input input--wide"
                    required
                    [(ngModel)]="_name"
                    (ngModelChange)="_nameChanged()"
                    [disabled]="_readonly"
                    lgUndoOnEsc
                    lgNextInputOnEnter
                    lgSelectOnFocus
                    lgDefaultFocus
                />
                <div
                    class="form-row__controls__message-error"
                    *ngIf="_name && !_nameValid"
                    lgTranslate
                >
                    .Name_must_be_unique
                </div>
            </div>
        </div>
        <div class="form-row form-row--input">
            <label class="form-row__label" lgTranslate>.Description_label</label>
            <div class="form-row__controls">
                <textarea
                    id="scenario-description"
                    [(ngModel)]="_description"
                    [disabled]="_readonly"
                    rows="2"
                    #description="ngModel"
                ></textarea>
            </div>
        </div>
    </div>

    <div class="form-group">
        <div class="form-row">
            <label class="form-row__label" lgTranslate>{{ _texts.itemsLabelLc }}</label>
            <div class="form-row__controls">
                {{ _texts.itemCountLc | lgTranslate : { count: _itemCount } }}
            </div>
        </div>
        <div class="form-row">
            <label class="form-row__label">&nbsp;</label>
            <div class="form-row__controls">
                <lg-button
                    (click)="_editItems()"
                    textLc="{{ _readonly ? _texts.viewItemsButtonLc : _texts.editItemsButtonLc }}"
                ></lg-button>
            </div>
        </div>
        <div *ngIf="_copyFromDropdown">
            <div class="form-row form-row--input">
                <label class="form-row__label" lgTranslate>.Copy_from_label</label>
                <div class="form-row__controls form-row__control-column">
                    <lg-dropdown
                        [definition]="_copyFromDropdown"
                        [(current)]="_copyFrom"
                    ></lg-dropdown>
                </div>
                <div
                    class="form-row__controls form-row__control-column form-row__control-column--fixed"
                >
                    <lg-button
                        button-class="right"
                        (click)="_copyItems()"
                        textLc=".Copy_button"
                        [isDisabled]="_copyFrom === '0_0'"
                    ></lg-button>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group form-group--buttons">
        <div class="form-row" *ngIf="!_readonly">
            <lg-button
                (click)="_remove()"
                buttonClass="button--error"
                class="form-row--button-left"
                textLc=".Delete_button"
                *ngIf="!_creating"
            ></lg-button>
            <lg-button
                (click)="_save()"
                buttonClass="button--primary"
                textLc=".Save_button"
                [isDisabled]="
                    !_nameValid || _itemCount === 0 || (!_modified && description.pristine)
                "
            ></lg-button>
            <lg-button (click)="_cancel()" textLc=".Cancel_button"></lg-button>
        </div>
        <div class="form-row" *ngIf="_readonly">
            <lg-button (click)="_close()" textLc=".Close_button"></lg-button>
        </div>
    </div>
</div>
