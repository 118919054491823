// Taken from Angular code and butchered (hardcoded reference to window). I wish the guys learned to share...

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
declare const Symbol: any;
let symbolIterator: any = null;
export function getSymbolIterator(): string | symbol {
    if (!symbolIterator) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const Symbol = window.Symbol;
        if (Symbol && Symbol.iterator) {
            symbolIterator = Symbol.iterator;
        } else {
            // es6-shim specific logic
            const keys = Object.getOwnPropertyNames(Map.prototype);
            for (const key of keys) {
                if (
                    key !== "entries" &&
                    key !== "size" &&
                    (Map as any).prototype[key] === Map.prototype.entries
                ) {
                    symbolIterator = key;
                }
            }
        }
    }
    return symbolIterator;
}
