import * as _ from "lodash";
import {
    Component,
    ViewEncapsulation,
    Input,
    ViewChild,
    TemplateRef,
    OnDestroy,
    AfterViewInit,
    OnChanges,
    Output,
    EventEmitter,
    ContentChild,
    isDevMode,
    inject
} from "@angular/core";
import { asapScheduler, BehaviorSubject, observeOn, Subject } from "rxjs";
import { LgSimpleChanges } from "@logex/framework/types";
import { LgDetachmentNotifierDirective, LgLoaderOverlayDirective } from "@logex/framework/ui-core";

import { LgPanelGridComponent } from "./lg-panel-grid.component";
import {
    LgEffectivePanelState,
    LgPanelOrderedTemplate,
    LgPanelContext,
    LgPanelState,
    LgPanelStateExtension,
    LgPanelRegistration,
    ILgPanelComponent,
    LgPanelResizeAction
} from "../service";
import { LgPanelBodyDirective } from "../directives";
import { AsyncPipe, NgIf, NgTemplateOutlet } from "@angular/common";

@Component({
    standalone: true,
    selector: "lg-panel",
    templateUrl: "./lg-panel.component.html",
    encapsulation: ViewEncapsulation.None,
    imports: [
        AsyncPipe,
        LgDetachmentNotifierDirective,
        LgLoaderOverlayDirective,
        NgIf,
        NgTemplateOutlet
    ],
    host: {
        "[hidden]": "true"
    }
})
export class LgPanelComponent implements ILgPanelComponent, OnDestroy, AfterViewInit, OnChanges {
    private _grid = inject(LgPanelGridComponent);

    @Input() panelClass: string | null = null;

    /**
     * Panel identifier (required).
     */
    @Input({ required: true }) id!: string;

    @Input() location: string;

    @Input()
    resizeActions: LgPanelResizeAction[] = ["toFullScreen", "toDefault"];

    @Input() loaderOverlay = false;

    @Input() loaderOverlayDelay: boolean | number | string = 0;

    @Input() loaderOverlaySkip?: HTMLElement;

    @Output() readonly stateChange = new EventEmitter<LgEffectivePanelState>();

    @Output() readonly maximizedChange = new EventEmitter<boolean>();

    @Output() readonly visibilityChange = new EventEmitter<boolean>();

    @ViewChild("panelTemplate", { static: true }) _panelTemplate: TemplateRef<LgPanelContext>;

    @ContentChild(LgPanelBodyDirective) _panelBody: LgPanelBodyDirective;

    get isMaximized(): boolean {
        return (
            this._effectiveState === LgPanelState.Expanded ||
            this._effectiveState === LgPanelState.Fullscreen
        );
    }

    get isFullscreen(): boolean {
        return this._effectiveState === LgPanelState.Fullscreen;
    }

    get isMinimized(): boolean {
        return this._effectiveState === LgPanelStateExtension.Minimized;
    }

    get isDetached(): boolean {
        return this._effectiveState === LgPanelStateExtension.Detached;
    }

    get isVisible(): boolean {
        return !this.isMinimized && !this.isDetached;
    }

    _resizeActionsChange = new Subject<LgPanelResizeAction[]>();

    _effectiveState: LgEffectivePanelState;
    _headerClass: string;
    _visibleHeaders: LgPanelOrderedTemplate[] = [];
    _visibleIcons: LgPanelOrderedTemplate[] = [];

    _context: LgPanelContext = {
        $implicit: this,
        maximized: false,
        minimized: false
    };

    private readonly _showLoaderOverlay = new BehaviorSubject<boolean>(false);
    protected _showLoaderOverlay$ = this._showLoaderOverlay.pipe(observeOn(asapScheduler));

    private readonly _destroyed$ = new Subject<void>();
    private _initialized = false;
    private _headers: LgPanelOrderedTemplate[] = [];
    private _icons: LgPanelOrderedTemplate[] = [];

    constructor() {
        const _loader = inject(LgLoaderOverlayDirective, { optional: true, self: true });

        if (isDevMode() && _loader) {
            console.warn(
                "lg-panel: lgLoaderOverlay directive not supported, please use [loaderOverlay] instead!"
            );
        }
    }

    ngAfterViewInit(): void {
        this._grid._addPanel(this);
        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgPanelComponent>): void {
        if (this._initialized) {
            if (changes.id) {
                this._grid._updatePanelId(this, changes.id.previousValue);
            }
            if (changes.location) {
                this._grid._updatePanelLocation(this);
            }
        }
        if (changes.resizeActions) {
            this._updateResizeActions();
        }
        if (changes.loaderOverlay) {
            this._showLoaderOverlay.next(this.loaderOverlay);
        }
    }

    ngOnDestroy(): void {
        this._grid._removePanel(this);
        this._resizeActionsChange.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    registerHeader(
        order: number,
        visibleWhenMinimized: boolean,
        visibleWhenMaximized: boolean,
        whenRegular: boolean,
        template: TemplateRef<LgPanelContext>
    ): LgPanelRegistration {
        const newEntry: LgPanelOrderedTemplate = {
            order,
            visibleWhenMinimized,
            visibleWhenMaximized,
            whenRegular,
            template
        };

        const index = _.findLastIndex(this._headers, h => h.order <= order);
        if (index === -1) {
            this._headers.unshift(newEntry);
        } else {
            this._headers.splice(index + 1, 0, newEntry);
        }

        this._updateHeaders(false);

        return {
            updateSettings: (
                whenMinimizedIntern: boolean,
                whenMaximizedIntern: boolean,
                whenRegularIntern: boolean
            ) => {
                newEntry.visibleWhenMinimized = whenMinimizedIntern;
                newEntry.visibleWhenMaximized = whenMaximizedIntern;
                newEntry.whenRegular = whenRegularIntern;
                this._updateHeaders(false);
            },
            remove: () => {
                _.pull(this._headers, newEntry);
                _.pull(this._visibleHeaders, newEntry);
            }
        };
    }

    registerIcon(
        order: number,
        whenMinimized: boolean,
        whenMaximized: boolean,
        whenRegular: boolean,
        template: TemplateRef<LgPanelContext>
    ): LgPanelRegistration {
        const newEntry: LgPanelOrderedTemplate = {
            order,
            visibleWhenMinimized: whenMinimized,
            visibleWhenMaximized: whenMaximized,
            whenRegular,
            template
        };

        const index = _.findIndex(this._icons, i => i.order <= order);
        if (index === -1) {
            this._icons.push(newEntry);
        } else {
            this._icons.splice(index, 0, newEntry);
        }
        this._updateIcons(false);

        return {
            updateSettings: (
                whenMinimizedIntern: boolean,
                whenMaximizedIntern: boolean,
                whenRegularIntern: boolean
            ) => {
                newEntry.visibleWhenMinimized = whenMinimizedIntern;
                newEntry.visibleWhenMaximized = whenMaximizedIntern;
                newEntry.whenRegular = whenRegularIntern;
                this._updateIcons(false);
            },
            remove: () => {
                _.pull(this._icons, newEntry);
                _.pull(this._visibleIcons, newEntry);
            }
        };
    }

    _setEffectiveState(state: LgEffectivePanelState): LgEffectivePanelState {
        const oldState = this._effectiveState;
        if (state !== oldState) {
            const wasVisible = this.isVisible;
            const wasMaximixed = this.isMaximized;
            this._effectiveState = state;
            this._context.minimized =
                this._effectiveState === LgPanelStateExtension.Minimized ||
                this._effectiveState === LgPanelStateExtension.Detached;
            this._context.maximized =
                this._effectiveState === LgPanelState.Expanded ||
                this._effectiveState === LgPanelState.Fullscreen;
            this._updateHeaders(true);
            this._updateIcons(true);
            this.stateChange.next(state);
            if (wasVisible !== this.isVisible) this.visibilityChange.next(this.isVisible);
            if (wasMaximixed !== this.isMaximized) this.maximizedChange.next(this.isMaximized);
        }
        return oldState;
    }

    private _updateHeaders(immediately: boolean): void {
        if (immediately || !this._initialized) {
            this._visibleHeaders = this._updateTemplates(this._headers);
        } else {
            Promise.resolve().then(
                () => (this._visibleHeaders = this._updateTemplates(this._headers))
            );
        }
    }

    private _updateIcons(immediately: boolean): void {
        if (immediately || !this._initialized) {
            this._visibleIcons = this._updateTemplates(this._icons);
        } else {
            Promise.resolve().then(() => (this._visibleIcons = this._updateTemplates(this._icons)));
        }
    }

    private _updateTemplates(list: LgPanelOrderedTemplate[]): LgPanelOrderedTemplate[] {
        const regular = !this._context.minimized && !this._context.maximized;
        return _.filter(
            list,
            entry =>
                (this._context.maximized && entry.visibleWhenMaximized) ||
                (this._context.minimized && entry.visibleWhenMinimized) ||
                (regular && entry.whenRegular)
        );
    }

    private _updateResizeActions(): void {
        if (this.resizeActions == null) {
            this.resizeActions = [];
        }

        if (this.resizeActions.length > 0) {
            if (this.resizeActions.indexOf("toDefault") === -1) {
                this.resizeActions.push("toDefault");
            }

            if (
                this.resizeActions.indexOf("toFullScreen") === -1 &&
                this.resizeActions.indexOf("expand") === -1
            ) {
                this.resizeActions.push("toFullScreen");
            }
        }

        this._resizeActionsChange.next(this.resizeActions);
    }
}
