import { NgModule, ModuleWithProviders, APP_INITIALIZER } from "@angular/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import { CommonModule } from "@angular/common";

import { LgForRoutesDirective } from "./navigation/lg-for-routes.directive";
import { LG_USER_STORAGE_SERVICE_GATEWAY } from "./user/lg-user-storage.types";
import { LgUserStorageGateway } from "./user/lg-user-storage.gateway";
import { LG_APP_SESSION } from "./application/app-session";
import { LgAppInitializerService, appInitFactory } from "./application/lg-app-initializer.service";
import { lgAppSessionMockFactory } from "./application/lg-app-session-mock.factory";
import { LG_AUTHENTICATION_SERVICE } from "./auth/authentication.types";
import { LgStubAuthenticationService } from "./auth/lg-stub-authentication.service";
import { LG_MESSAGE_BUS_SERVICE } from "./network/lg-message-bus.types";
import { LgMessageBusOldService } from "./network/lg-message-bus-old.service";
import { LG_APP_CONTROL, LgAppControlV1Service } from "./application/app-control.service";
import { LG_USERFLOW_SERVICE } from "./integration/userflow/userflow.types";
import { LgUserflowService } from "./integration/userflow/userflow.service";

@NgModule({
    imports: [CommonModule, LgForRoutesDirective, UiCoreModule],
    exports: [LgForRoutesDirective]
})
export class LgApplicationModule {
    static forRoot(): ModuleWithProviders<LgApplicationModule> {
        return {
            ngModule: LgApplicationModule,
            providers: [
                {
                    provide: LG_USER_STORAGE_SERVICE_GATEWAY,
                    useClass: LgUserStorageGateway
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: appInitFactory,
                    deps: [LgAppInitializerService],
                    multi: true
                },
                {
                    provide: LG_APP_SESSION,
                    useFactory: lgAppSessionMockFactory
                },
                {
                    provide: LG_AUTHENTICATION_SERVICE,
                    useClass: LgStubAuthenticationService
                },
                {
                    provide: LG_MESSAGE_BUS_SERVICE,
                    useClass: LgMessageBusOldService
                },
                {
                    provide: LG_APP_CONTROL,
                    useClass: LgAppControlV1Service
                },
                {
                    provide: LG_USERFLOW_SERVICE,
                    useExisting: LgUserflowService
                }
            ]
        };
    }
}
