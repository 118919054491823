import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { LgMessageBusService, LG_MESSAGE_BUS_SERVICE } from "@logex/framework/lg-application";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgPromptDialog } from "@logex/framework/ui-core";

@Injectable({ providedIn: "root" })
export class LgDownloadService {
    protected _httpClient = inject(HttpClient);
    protected _messageBusService = inject(LG_MESSAGE_BUS_SERVICE);
    private _promptDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);

    do(url: string, params: any): Observable<HttpResponse<Blob>> {
        return this._httpClient
            .request("POST", url, {
                params: {
                    [LgMessageBusService.connectionIdParamName]:
                        this._messageBusService.getConnectionId()
                },
                body: params,
                responseType: "blob",
                observe: "response"
            })
            .pipe(
                catchError(error => {
                    this._promptDialog.alert(
                        this._lgTranslate.translate("FW.FAILED_REQUEST_TO_SERVER"),
                        this._formatServerError(error),
                        {
                            columns: 5,
                            buttons: [LgPromptDialog.CLOSE_BUTTON]
                        }
                    );
                    throw error;
                }),

                tap(data => {
                    const blob = new Blob([data.body], {
                        type: data.headers.get("Content-Type")
                    });

                    const contentDispositionValue = data.headers.get("Content-Disposition");
                    const fallbackFilename = /filename="(.*)"/gi
                        .exec(contentDispositionValue)
                        ?.pop();
                    const fileNameUtf8 = /filename\*=UTF-8''(.*)/gi
                        .exec(contentDispositionValue)
                        ?.pop();

                    const fileName =
                        fileNameUtf8 != null ? decodeURIComponent(fileNameUtf8) : fallbackFilename;

                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            );
    }

    private _formatServerError(error: HttpErrorResponse): string {
        let errorText = "Error status " + error.status;
        if (error.error != null) {
            if (error.error.Message != null) {
                errorText = error.error.Message;
                if (error.error.ExceptionMessage != null) {
                    errorText += `<br /><b>${error.error.ExceptionMessage}</b>`;
                }
                if (error.error.StackTrace != null) {
                    errorText += `<br /><div class="confirm-message__section confirm-message__scrollable" ><b>Stack:</b><br />
                        ${error.error.StackTrace.replace(/\n/g, "<br/>")}</div>`;
                }
            } else {
                const find = /<b>\s*Description:\s*<\/b>([^<]*)<br>/.exec(error.error.data);
                if (find) {
                    errorText = find[1];
                }
            }
        }

        return errorText;
    }
}
