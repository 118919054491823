import _ from "lodash";
import { Injectable, TemplateRef, Injector, OnDestroy, inject } from "@angular/core";
import { ComponentPortal, ComponentType, TemplatePortal } from "@angular/cdk/portal";
import { filter, take, takeUntil } from "rxjs/operators";

import { LgOverlayService } from "../lg-overlay/lg-overlay.service";
import { IDialogOptions, LG_DIALOG_DATA, IDialogShowFinalizer } from "./lg-dialog.types";
import { LgDialogHolderComponent } from "./lg-dialog-holder.component";
import { LgDialogRef } from "./lg-dialog-ref";
import { GuardsCheckEnd, Router } from "@angular/router";
import { Subject } from "rxjs";

@Injectable()
export class LgDialogService implements OnDestroy {
    private _injector = inject(Injector);
    private _overlayService = inject(LgOverlayService);
    private _parentService = inject(LgDialogService, { optional: true, skipSelf: true });

    constructor() {
        const router = inject(Router, { optional: true });

        if (this._parentService) {
            this._holderInstances = this._parentService._holderInstances;
            this._stack = this._parentService._stack;
        } else {
            this._holderInstances = {};
            this._stack = [];
            if (router) {
                router.events
                    .pipe(
                        // we should know the navigation will happen (ignoring errors), but while the old component is still visible
                        filter(event => event instanceof GuardsCheckEnd),
                        takeUntil(this._destroyed$)
                    )
                    .subscribe(() => this._onNavigation());
            }
        }
    }

    private _idCounter = 0;
    private _holderInstances: _.Dictionary<LgDialogHolderComponent>;
    private _stack: Array<LgDialogRef<unknown>>;
    private readonly _destroyed$ = new Subject<void>();

    public show<T, D = any>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        options: IDialogOptions<D>,
        finalize?: IDialogShowFinalizer<T, D>
    ): LgDialogRef<T> {
        const myId = this._getDialogId();

        options = _.extend(
            {
                title: "Dialog",
                allowClose: true,
                parameters: {}
            },
            options
        );

        const overlay = this._overlayService.show(myId, {
            class: "lg-overlay__disabled",
            hasBackdrop: true,
            trapFocus: true
        });

        const portal = new ComponentPortal(LgDialogHolderComponent, options.viewContainerRef);
        const dialogHolderInstance = overlay.overlayRef.attach(portal).instance;

        const dialogRef = new LgDialogRef<T>(myId, options, overlay, dialogHolderInstance);
        this._stack.push(dialogRef);

        if (componentOrTemplateRef instanceof TemplateRef) {
            const templatePortal = new TemplatePortal<T>(componentOrTemplateRef, null, <any>{
                $implicit: options.data,
                dialogRef
            });
            dialogHolderInstance._attachTemplate(templatePortal);
        } else {
            const injector = this._createInjector<T>(options, dialogRef, dialogHolderInstance);
            const componentPortal = new ComponentPortal(
                componentOrTemplateRef,
                undefined,
                injector
            );
            dialogRef.componentInstance =
                dialogHolderInstance._attachComponent(componentPortal).instance;
        }

        const success = this._getFinalizer(
            finalize,
            options,
            dialogRef.componentInstance,
            (newOptions: IDialogOptions<D>) => {
                newOptions = newOptions || options;

                dialogRef.finalizedOptions(newOptions);
                dialogRef._holderInstance.initialize(
                    newOptions,
                    newOptions.relatedTo && this._holderInstances[newOptions.relatedTo.id],
                    overlay.focusFirstTabbableElement
                );

                this._holderInstances[dialogRef.id] = dialogHolderInstance;

                dialogRef
                    .beforeClosed()
                    .pipe(take(1))
                    .subscribe(() => {
                        delete this._holderInstances[dialogRef.id];
                        this._stack.splice(this._stack.indexOf(dialogRef), 1);
                    });
            }
        );

        if (!success) {
            dialogRef._holderInstance.initialize(options, null, () => false);
            dialogRef.close(true);
            return null;
        }

        return dialogRef;
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private _getDialogId(): string {
        if (this._parentService) return this._parentService._getDialogId();

        return "Dialog" + ++this._idCounter;
    }

    private _getFinalizer<T, D>(
        finalizeOptions: IDialogShowFinalizer<T, D> | undefined,
        currentOptions: IDialogOptions<D>,
        instance: T,
        doFinish: (newOptions: IDialogOptions<D>) => void
    ): boolean {
        if (!finalizeOptions) {
            doFinish(currentOptions);
            return true;
        }

        try {
            finalizeOptions(currentOptions, instance, doFinish);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    private _createInjector<T>(
        options: IDialogOptions<any>,
        dialogRef: LgDialogRef<T>,
        holder: LgDialogHolderComponent
    ): Injector {
        const userInjector = options.viewContainerRef && options.viewContainerRef.injector;
        return Injector.create({
            parent: userInjector || this._injector,
            providers: [
                {
                    provide: LgDialogHolderComponent,
                    useValue: holder
                },
                {
                    provide: LG_DIALOG_DATA,
                    useValue: options.data
                },
                {
                    provide: LgDialogRef,
                    useValue: dialogRef
                }
            ]
        });
    }

    private _onNavigation(): void {
        // close the dialogs in loop in case the operation itself causes cascade of changes
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const dialog = _.findLast(
                this._stack,
                ref => ref.visible && (ref.options.forceCloseOnNavigation ?? true)
            );
            if (!dialog) break;
            dialog.close(null, true);
        }
    }
}
