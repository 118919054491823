import { getSymbolIterator } from "@logex/framework/utilities";

/**
 * Implements simple circular buffer that can be iterated over. In addition to that, it can also serve as
 * (read-only) view to prefilled array.
 * The implementation details are very specific to the needs of virtual-repeat and you most likely don't
 * want to use it anywhere else.
 *
 * Note that in order to avoid any allocations, only 1 iterator at a time can be active!
 */
export class CircularBuffer<T> implements Iterator<T>, Iterable<T> {
    public count: number;
    public capacity: number;

    private _items: T[];
    private _offset: number;
    private _readPosition: number;
    private _writePosition: number;

    private _isView = false;

    /**
     * Reset the buffer to store at most capacity items in its own array
     *
     * @param capacity
     */
    public reset(capacity: number): void {
        if (capacity !== this.capacity || this._isView) {
            this._items = new Array<T>(capacity);
            this.capacity = capacity;
            this._isView = false;
        }
        this.count = 0;
        this._offset = 0;
        this._readPosition = 0;
        this._writePosition = 0;
    }

    /**
     * Reset the buffer to provide view of array obtained elsewhere; in this state it can
     * serve only for iteration. Note tha the buffer tries to provide "count" number
     * of items if it can, which means it may move the offset backwards (if count is
     * past the actual buffer size)
     *
     * @param capacity The maximum capacity (not really relevant, just for compatibility)
     * @param buffer  The buffer containing the values
     * @param offset Offset in the buffer where data start
     * @param count The number of items stored in the buffer
     */
    public setAsView(capacity: number, buffer: T[], offset: number, count: number): void {
        this._items = buffer;
        this.capacity = capacity;
        const overItems = offset + count - buffer.length;
        if (overItems > 0) {
            const adjust = Math.min(offset, overItems);
            this._offset = offset - adjust;
            this.count = buffer.length - this._offset;
        } else {
            this._offset = offset;
            this.count = count;
        }
        this._readPosition = 0;
        this._writePosition = this.count;
        this._isView = true;
    }

    /**
     * Add another item to the buffer. This is invalid operation in view mode
     */
    public add(value: T): void {
        if (this._isView) {
            console.error("Cannot write to view CircularBuffer");
            return;
        }
        this._items[this._offset + this._writePosition] = value;
        if (this.count < this.capacity) {
            ++this.count;
        }
        this._writePosition = (this._writePosition + 1) % this.capacity;
    }

    [getSymbolIterator()](): Iterator<T> {
        this._readPosition = 0;
        return this;
    }

    [Symbol.iterator](): Iterator<T> {
        this._readPosition = 0;
        return this;
    }

    public next(_value?: any): IteratorResult<T> {
        if (this._readPosition >= this.count) {
            return {
                done: true,
                value: null
            };
        }

        return {
            done: false,
            value: this._items[
                this._offset +
                    ((this._writePosition - this.count + this._readPosition++ + this.capacity) %
                        this.capacity)
            ]
        };
    }
}
