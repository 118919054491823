import { ViewportScroller } from "@angular/common";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { Subscription } from "rxjs";
import { LgViewportScrollerService } from "./lg-viewport-scroller.service";

/**
 * Custom scroll position manager intended for use with LgViewportScrollableDirective.
 */
@Injectable()
export class LgViewportScroller implements ViewportScroller, OnDestroy {
    private _viewportStateService = inject(LgViewportScrollerService);
    private _scrollPosition: [number, number] = [0, 0];
    private _scrollSubscription: Subscription | null = null;

    constructor() {
        this._observeScrollPosition();
    }

    ngOnDestroy(): void {
        this._scrollSubscription?.unsubscribe();
    }

    getScrollPosition(): [number, number] {
        return this._scrollPosition;
    }

    scrollToPosition(position: [number, number]): void {
        this._viewportStateService.scrollToPosition(position);
    }

    scrollToAnchor(anchor: string): void {
        this._viewportStateService.scrollToAnchor(anchor);
    }

    setOffset(offset: [number, number] | (() => [number, number])): void {
        if (Array.isArray(offset)) {
            this._viewportStateService.setAnchorOffset(offset);
        } else {
            this._viewportStateService.setAnchorOffset(offset());
        }
    }

    /**
     * Disables default browser scroll restoration.
     */
    setHistoryScrollRestoration(scrollRestoration: "auto" | "manual"): void {
        if (this._supportScrollRestoration()) {
            const history = window.history;
            if (history && history.scrollRestoration) {
                history.scrollRestoration = scrollRestoration;
            }
        }
    }

    private _observeScrollPosition(): void {
        this._scrollSubscription = this._viewportStateService.scrollState$.subscribe(
            (position: [number, number]) => {
                this._scrollPosition = position;
            }
        );
    }

    private _supportScrollRestoration(): boolean {
        try {
            if (!this._supportsScrolling()) {
                return false;
            }
            const scrollRestorationDescriptor =
                this._getScrollRestorationProperty(window.history) ||
                this._getScrollRestorationProperty(Object.getPrototypeOf(window.history));
            return (
                !!scrollRestorationDescriptor &&
                !!(scrollRestorationDescriptor.writable || scrollRestorationDescriptor.set)
            );
        } catch {
            return false;
        }
    }

    private _supportsScrolling(): boolean {
        try {
            return !!window && !!window.scrollTo && "pageXOffset" in window;
        } catch {
            return false;
        }
    }

    private _getScrollRestorationProperty(obj: any): PropertyDescriptor | undefined {
        return Object.getOwnPropertyDescriptor(obj, "scrollRestoration");
    }
}
