import * as _ from "lodash";
import { Injectable, inject } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { LgHelpTooltipServiceGateway } from "./gateway/lg-help-tooltip-gateway";
import { LgHelpTooltipItem } from "./types/types";

const THROTTLE_INTERVAL = 50;

interface RequestedTooltip {
    key: string;
    lang: string;
}

@Injectable({ providedIn: "root" })
export class LgHelpTooltipService {
    private _gateway = inject(LgHelpTooltipServiceGateway);

    private _cache: _.Dictionary<Promise<LgHelpTooltipItem>> = {};
    private _requestedTooltips: RequestedTooltip[] = [];
    private _throttleHandler: any;
    private _responseHandlers: Record<string, (value: LgHelpTooltipItem) => void> = {};

    async getValue(key: string, lang?: string): Promise<LgHelpTooltipItem> {
        if (this._cache[key] != null) {
            return this._cache[key];
        }

        this._cache[key] = new Promise<LgHelpTooltipItem>(resolve => {
            this._responseHandlers[key] = resolve;
        });

        this._requestedTooltips.push({ key, lang });

        clearTimeout(this._throttleHandler);
        this._throttleHandler = setTimeout(async () => {
            const requests = this._requestedTooltips;
            const handlers = this._responseHandlers;
            this._requestedTooltips = [];
            this._responseHandlers = {};
            await this._loadTooltips(requests, handlers);
        }, THROTTLE_INTERVAL);

        return this._cache[key];
    }

    private async _loadTooltips(
        requestedTooltips: RequestedTooltip[],
        responseHandlers: Record<string, (value: LgHelpTooltipItem) => void>
    ): Promise<void> {
        const requestedTooltipsGroupedByLang = _.groupBy(requestedTooltips, x => x.lang);
        const languages = Object.keys(requestedTooltipsGroupedByLang);

        for (const x of languages) {
            const requestedTooltips = requestedTooltipsGroupedByLang[x];
            const requestedTooltipKeys = requestedTooltipsGroupedByLang[x].map(x => x.key);
            const lang = requestedTooltips[0].lang; // might be grouped by "undefined" and we don't want to pass that to the API

            const tooltipsData = await firstValueFrom(
                this._gateway.getValues(requestedTooltipKeys, lang)
            );

            if (tooltipsData.tooltips?.length > 0) {
                for (const tooltip of tooltipsData.tooltips) {
                    responseHandlers[tooltip.id](tooltip);
                }

                // Resolve the handlers for which no tooltip data has been received (in order to keep Promise.all calls loading multiple tooltips working)
                const loadedTooltipIds = tooltipsData.tooltips.map(x => x.id);
                const missingTooltipIds = Object.keys(responseHandlers).filter(
                    x => !loadedTooltipIds.includes(x)
                );
                for (const x of missingTooltipIds) {
                    responseHandlers[x](null);
                }
            }
        }
    }
}
