import { inject, Injectable } from "@angular/core";
import {
    ApplicationTraceSeverity,
    IApplicationEventTracer,
    LG_APPLICATION_MULTI_EVENT_TRACERS
} from "./application-event-tracer";
import { ApplicationEventTracerHubFilter } from "./application-event-tracer-hub-filter";

@Injectable({ providedIn: "root" })
export class ApplicationEventTracerHub implements IApplicationEventTracer {
    private _filter = inject(ApplicationEventTracerHubFilter);
    private _tracers = inject(LG_APPLICATION_MULTI_EVENT_TRACERS, { optional: true }) ?? [];

    pageChange(): void {
        if (this._tracersEmpty) return;

        this._tracers.forEach(tracer => {
            if (this._filter.sendPageChange(tracer.tracerName)) {
                tracer.pageChange();
            }
        });
    }

    trackEvent(category: string, action: string, label?: string, value?: number): void {
        if (this._tracersEmpty) return;

        this._tracers.forEach(tracer => {
            if (this._filter.sendTrackEvent(tracer.tracerName, category, action, label, value)) {
                tracer.trackEvent(category, action, label, value);
            }
        });
    }

    trackTime(category: string, variable: string, value: number, label?: string): void {
        if (this._tracersEmpty) return;

        this._tracers.forEach(tracer => {
            if (this._filter.sendTrackTime(tracer.tracerName, category, variable, value, label)) {
                tracer.trackTime(category, variable, value, label);
            }
        });
    }

    trackTrace(
        severity: ApplicationTraceSeverity,
        message: string,
        customProperties?: unknown
    ): void {
        if (this._tracersEmpty) return;

        this._tracers.forEach(tracer => {
            if (
                this._filter.sendTrackTrace(tracer.tracerName, severity, message, customProperties)
            ) {
                tracer.trackTrace(severity, message, customProperties);
            }
        });
    }

    trackException(exception: Error, customProperties?: unknown): void {
        if (this._tracersEmpty) return;

        this._tracers.forEach(tracer => {
            if (this._filter.sendTrackException(tracer.tracerName, exception, customProperties)) {
                tracer.trackException(exception, customProperties);
            }
        });
    }

    private get _tracersEmpty(): boolean {
        return this._tracers == null || this._tracers.length === 0;
    }
}
