import _ from "lodash";
import { Injectable } from "@angular/core";

import { ILookup } from "@logex/framework/types";

interface IBrowser {
    /**
     * Parsed version
     */
    version: string;

    chrome?: boolean;
    safari?: boolean;
    webkit?: boolean;
    msie?: boolean;
    opera?: boolean;
    mozilla?: boolean;
}

function uaMatch(ua: string): { browser: string; version: string } {
    ua = ua.toLowerCase();

    const match =
        /(chrome)[ /]([\w.]+)/.exec(ua) ||
        /(webkit)[ /]([\w.]+)/.exec(ua) ||
        /(opera)(?:.*version|)[ /]([\w.]+)/.exec(ua) ||
        /(msie) ([\w.]+)/.exec(ua) ||
        /(trident)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
        (ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua)) ||
        [];

    return {
        browser: match[1] || "",
        version: match[2] || "0"
    };
}

const matched = uaMatch(navigator.userAgent);
const browser: IBrowser = { version: null };

if (matched.browser) {
    if (matched.browser === "trident") matched.browser = "msie";
    (browser as Record<string, any>)[matched.browser] = true;
    browser.version = matched.version;
}

if (browser.chrome) {
    browser.webkit = true;
} else if (browser.webkit) {
    browser.safari = true;
}

// -------------------------------------------------------------------------------------
/**
 * @deprecated use utility functions from "@logex/framework/utilities" instead
 */
@Injectable({ providedIn: "root" })
export class UtilityService implements UtilityService {
    /**
     * @deprecated
     * Regular expression used by sanitizeForRegexp()
     */
    public sanitizingRegExp = /[#-.]|[[-^]|[?|{}]/g;

    /**
     * @deprecated use buildLookup function from "@logex/framework/utilities"
     * Build a lookup for the specified item, based on one their property (which should be string or number).
     * No special handling is done for duplicate keys (the last item will be stored in the lookup)
     *
     * @param array is the input array of items to assign
     * @param keyName is the name of the property to lookup
     * @returns lookup based on the value of the specified property
     */
    public buildLookup<T>(array: T[], keyName: string): ILookup<T>;
    public buildLookup(array: any[], keyName: string): ILookup<any>;
    public buildLookup(array: any[], keyName: string): ILookup<any> {
        const result: ILookup<any> = {};
        if (array) {
            for (let i = 0, l = array.length; i < l; ++i) {
                const e = array[i];
                result[e[keyName]] = e;
            }
        }
        return result;
    }

    /**
     * @deprecated use isPromise function from "@logex/framework/utilities"
     * Return true if given object is promise.
     *
     * @param obj
     */
    public isPromise(obj: unknown): obj is Promise<any> {
        return obj != null && typeof obj === "object" && "then" in obj && _.isFunction(obj.then);
    }

    /**
     * @deprecated use sanitizeForRegexp from "@logex/framework/utilities"
     * Sanitize the specified string so that it can be used to create a regular expresion used to search it in another string
     *
     * @example var r = new RegExp( this.utility.sanitizeForRegexp(filterBy), "i"); r.match("ItemName")
     *
     * @param str the string to be sanitized
     */
    public sanitizeForRegexp(str: string): string {
        return str.replace(this.sanitizingRegExp, "\\$&");
    }
}

/*
todo:
    * make the old and new filter definitions compatible?
    * consider special storage for numerical keys (no quotations)

*/
