import {
    ElementRef,
    Renderer2,
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    inject
} from "@angular/core";
import { FileUploader } from "ng2-file-upload";

import { Upload } from "./lg-upload-resumable.types";

@Component({
    selector: "lg-upload-resumable",
    templateUrl: "./lg-upload-resumable.component.html"
})
export class LgUploadResumableComponent {
    _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    @Input("uploads") _uploads: Upload[] = [];

    @Output("uploadsAdded") readonly uploadsAdded: EventEmitter<File[]> = new EventEmitter();
    @Output("uploadDeleted") readonly uploadDeleted: EventEmitter<number> = new EventEmitter();

    @ViewChild("fileInput") _fileInput: ElementRef<HTMLInputElement>;
    @Input("uploader") _uploader!: FileUploader;
    @Input() smallUploadZone = false;

    _dragging = false;
    _instance: number;

    private static _instanceCounter = 0;

    constructor() {
        this._instance = ++LgUploadResumableComponent._instanceCounter;
        // Adding styles this way in case component will be used as a base
        this._renderer.addClass(this._elementRef.nativeElement, "lg-upload-form");
        this._uploader = new FileUploader({ url: "" });
    }

    _draggingOverInput(dragging: boolean): void {
        this._dragging = dragging;
    }

    _fileSelected($e: Event): void {
        const files: File[] = Array.from((<HTMLInputElement>$e.target).files);
        this._dropped(files);
    }

    _dropped(files: File[]): void {
        this.uploadsAdded.emit(files);
        this._fileInput.nativeElement.value = "";
        this.smallUploadZone = true;
    }

    _deleteUpload(index: number): void {
        this.uploadDeleted.emit(index);
    }

    _getUploadDisplayedSize(upload: Upload): string {
        const specifiedFileSize = parseInt(upload.options.metadata?.fileSize);
        const fileSize = !isNaN(specifiedFileSize) ? specifiedFileSize : upload.file.size;
        const totalSize = Math.round(fileSize / 1024 / 1024);
        if (upload.options.progress) {
            return upload.options.size;
        }
        return `0 MB / ${totalSize} MB`;
    }
}
