import _ from "lodash";
import { Injectable, Component, ElementRef, inject } from "@angular/core";
import {
    Overlay,
    OverlayPositionBuilder,
    OverlayRef,
    PositionStrategy,
    ScrollStrategy,
    ScrollStrategyOptions
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { InteractivityChecker } from "@angular/cdk/a11y";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { LgFocusTrap, LgFocusTrapFactory, getFirstTabbableElementAfter } from "../helpers";
import { IStringLookup } from "@logex/framework/types";
import { closestElement } from "@logex/framework/utilities";

// ---------------------------------------------------------------------------------------------
//  Interfaces
// ---------------------------------------------------------------------------------------------
export interface IOverlayResultApi {
    /**
     * Hide the layer
     */
    hide(): void;

    /**
     * Returns true, if the layer is on top of the stack
     */
    isTop(): boolean;

    /**
     * Return true, if the layer is top active (there is no other layer above with trapFocus enabled)
     */
    isActive(): boolean;

    /**
     * Return the underlying CDK overlay reference
     */
    overlayRef: OverlayRef;

    /**
     * Focus on first tabbable element in the layer. This works only for trapFocus layers.
     * Returns false, if no suitable element was found
     */
    focusFirstTabbableElement(): boolean;
}

export interface IOverlayOptions {
    /**
     * Specify the overlay's class. Default is "empty-overlay" (invisible element that hides controls below), but alternatively
     * you can also specify "shaded-overlay" (transparent dark overlay), or "wait-overlay" (invisible element with wait cursor)
     */
    class?: string;

    hasBackdrop?: boolean;

    /** Specify class of the panel */
    panelClass?: string;

    /**
     * Optionally override opacity of the overlay
     */
    // opacity?: number;

    /**
     * Function that will be called when the overlay is clicked (within $apply)
     */
    onClick?: () => void;

    /**
     * When specified, the overlay will attempt to trap the focus to input elements within the overlay
     */
    trapFocus?: boolean;

    /**
     * The element which is "source" of the overlay. Currently this is used only together with trapFocusTo - when
     * the overlay regains focus, it will try to focus INPUT elements after the source
     */
    sourceElement?: ElementRef;

    /**
     * Specify what should happen, when the overlay is hidden:
     * "blur" - No element is focused
     * "restore" - Last selected element is focused
     * "focusNext" - Next element is focused
     * "ignore" - Focus is not affected by overlay service.
     *
     * Default is "restore"
     */
    focusPostHide?: "blur" | "restore" | "focusNext" | "ignore";

    /**
     * If specified, the overlay will block all the keyboard inputs apart from ctrl+r
     */
    blockAll?: boolean;

    /**
     * If specified, the callback will be called whenever the overlay regains focus after being previous deactivated
     * (that is, covered by another overlay).
     */
    onActivate?: (target: OverlayRef, targetId: string, oldOverlayId: string) => void;

    /**
     * If specified, the callback will be called whenever the overlay is covered by another overlay
     */
    onDeactivate?: (target: OverlayRef, targetId: string, newOverlayId: string) => void;

    positionStrategy?: PositionStrategy;
    scrollStrategy?: ScrollStrategy;
}

// ---------------------------------------------------------------------------------------------
//  Implementation
// ---------------------------------------------------------------------------------------------
interface IStackEntry {
    id: string;
    sourceElement: ElementRef;
    overlayRef: OverlayRef;
    blockAll: boolean;
    lastFocus: HTMLElement | null;
    focusTrap: LgFocusTrap | null;
    focusNextOnHide: "blur" | "restore" | "focusNext" | "ignore";
    clickSubscription: Subscription;
    api: IOverlayResultApi;

    onActivate?: (target: OverlayRef, targetId: string, oldOverlayId: string) => void;
    onDeactivate?: (target: OverlayRef, targetId: string, newOverlayId: string) => void;
}

@Injectable({ providedIn: "root" })
export class LgOverlayService {
    private _focusTrapFactory = inject(LgFocusTrapFactory);
    private _interactivityChecker = inject(InteractivityChecker);
    private _overlay = inject(Overlay);
    private stack: IStackEntry[] = [];
    private visible: IStringLookup<IStackEntry> = {};
    private counter = 0;
    private lastBodyFocus: HTMLElement;

    /**
     * Return position strategy builder (forward call to CDK overlay.position)
     */
    get positionStrategies(): OverlayPositionBuilder {
        return this._overlay.position();
    }

    /**
     * Return scroll strategy builder (forward call to CDK overlay.scrollStrtegies)
     */
    get scrollStrategies(): ScrollStrategyOptions {
        return this._overlay.scrollStrategies;
    }

    /**
     * Show new overlay, optionally with specific ID
     *
     * @param   id       id of the overlay (otherwise, one is automatically generated)
     * @param   options  options specifying the exact behaviour
     *
     * @return  Returns the api that can be used to interact with the new overlay
     */
    show(id?: string, options?: IOverlayOptions): IOverlayResultApi;
    show(options?: IOverlayOptions): IOverlayResultApi;
    show(id?: string | IOverlayOptions, options?: IOverlayOptions): IOverlayResultApi {
        if (!_.isString(id)) {
            options = id;
            id = "ANON" + this.counter++;
        }
        options = options || {};

        if (this.visible[id]) {
            alert("Overlay already visible! " + id);
            return null;
        }

        const className = options.class || "empty-overlay";
        /* CDK bugs on this
        if (this.stack.length) {
            className += " secondary-overlay";
        }
        */

        const overlayRef = this._overlay.create({
            backdropClass: className,
            panelClass: options.panelClass,
            hasBackdrop: options.hasBackdrop,
            scrollStrategy: options.scrollStrategy,
            positionStrategy: options.positionStrategy
        });

        // if (options.opacity) div.css({ opacity: options.opacity });
        let clickSubscription: Subscription;

        if (options.onClick) {
            clickSubscription = overlayRef.backdropClick().subscribe(() => {
                options.onClick();
            });
        }

        if (this.stack.length && this.stack[this.stack.length - 1].onDeactivate) {
            this.stack[this.stack.length - 1].onDeactivate(
                this.stack[this.stack.length - 1].overlayRef,
                this.stack[this.stack.length - 1].id,
                id
            );
        }

        const stackEntry: IStackEntry = (this.visible[id] = {
            overlayRef,
            id,
            onDeactivate: options.onDeactivate,
            onActivate: options.onActivate,
            blockAll: options.blockAll,
            sourceElement: options.sourceElement,
            lastFocus: null,
            focusNextOnHide: options.focusPostHide ?? "focusNext",
            clickSubscription,
            focusTrap: null,
            api: null!
        });
        this.stack.push(stackEntry);

        if (this.stack[this.stack.length - 2]) {
            this.stack[this.stack.length - 2].lastFocus = this._getFocusedElement();
        } else {
            this.lastBodyFocus = this._getFocusedElement();
        }

        if (options.trapFocus) {
            overlayRef
                .attachments()
                .pipe(take(1))
                .subscribe(() => {
                    stackEntry.focusTrap = this._focusTrapFactory.create(overlayRef.overlayElement);
                    stackEntry.focusTrap.focusFirstTabbableElementForOverlayWhenReady();
                });
        }

        if (options.focusPostHide !== "ignore") {
            this._blurFocused();
        }

        const api: IOverlayResultApi = {
            hide: () => {
                this.hide(id as string);
                api.hide = () => undefined;
                api.isTop = () => {
                    if (window.console) console.error("Overlay already hidden");
                    return false;
                };
            },

            isTop: () => {
                return this.stack.length && this.stack[this.stack.length - 1].id === id;
            },

            isActive: () => {
                const index = this.stack.findIndex(entry => entry.id === id);
                if (index === -1) return false;
                return this.stack.slice(index + 1).find(entry => entry.focusTrap) === undefined;
            },

            overlayRef,

            focusFirstTabbableElement: () => {
                const topStack = this.stack.length && this.stack[this.stack.length - 1];
                return (
                    topStack &&
                    topStack.id === id &&
                    topStack.focusTrap &&
                    topStack.focusTrap.focusFirstTabbableElementForOverlay()
                );
            }
        };

        stackEntry.api = api;
        return api;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Show new "wait overlay". This overlay is intended to block input on the UI due to some (typically backend)
     * operation, and covers the whole screen.
     *
     * @param   id       Id of the overlay (optional - if none specified, it will be generate)
     * @param   options  Specification of the exact behaviour
     *
     * @return  Returns the api that can be used to interact with the new overlay
     */
    showWaitOverlay(id?: string, options?: IOverlayOptions): IOverlayResultApi;
    showWaitOverlay(options?: IOverlayOptions): IOverlayResultApi;
    showWaitOverlay(id?: string | IOverlayOptions, options?: IOverlayOptions): IOverlayResultApi {
        if (!_.isString(id)) {
            options = id;
            id = "ANON" + this.counter++;
        }
        options = _.extend(
            {
                class: "wait-overlay",
                blockAll: true,
                width: "100%",
                height: "100%"
            },
            options
        );
        const position = this._overlay.position().global().centerHorizontally().centerVertically();
        options.positionStrategy = position;
        const portal = new ComponentPortal(WaitOverlayComponent);
        const result = this.show(id, options);
        result.overlayRef.attach(portal);
        return result;
    }

    /**
     * Hide the specified overlay, or the top overlay, if no ID is specified
     *
     * @param   id  Id of the overlay to hide
     */
    hide(id?: string): void {
        if (!id) {
            id = this.stack.length ? this.stack[this.stack.length - 1].id : null;
        }

        if (!this.visible[id]) {
            alert("Overlay not visible: " + (id || ""));
            return;
        }

        const toBeRemoved = this.visible[id];
        const focusOnHide = toBeRemoved.focusNextOnHide;
        const sourceElement = toBeRemoved.sourceElement;
        this.stack.splice(this.stack.indexOf(toBeRemoved), 1);
        if (toBeRemoved.clickSubscription) toBeRemoved.clickSubscription.unsubscribe();
        toBeRemoved.overlayRef.dispose();
        delete this.visible[id];

        if (toBeRemoved.focusTrap) toBeRemoved.focusTrap.destroy();

        const topOverlay = this.stack[this.stack.length - 1];
        const trap = topOverlay?.focusTrap;

        switch (focusOnHide) {
            case "blur": {
                this._blurFocused();
                break;
            }

            case "restore": {
                if (topOverlay != null) {
                    if (topOverlay.lastFocus != null) {
                        setTimeout(() => topOverlay.lastFocus.focus(), 0);
                    } else {
                        this._blurFocused();
                    }
                    break;
                }

                if (this.lastBodyFocus != null) {
                    this.lastBodyFocus.focus();
                } else {
                    this._blurFocused();
                }

                break;
            }

            case "focusNext": {
                if (trap == null) break;

                if (topOverlay != null) {
                    const after = sourceElement?.nativeElement || topOverlay.lastFocus;
                    trap.focusFirstTabbableElementAfterWhenReady(after, true);
                    break;
                }

                if (this.lastBodyFocus != null) {
                    trap.focusFirstTabbableElementAfterWhenReady(this.lastBodyFocus, true);
                }

                break;
            }
        }

        if (topOverlay?.onActivate != null) {
            topOverlay.onActivate(topOverlay.overlayRef, topOverlay.id, id);
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Focus on the first tabbable element after specified node in the topmost layer with focustrap,
     * or inside document if there's none. Return the target element, or null
     *
     * @param after specifies element after which we should search. If null, look for first element instead
     * @param wrapAround specifies, whether we should go back to beginning, or keep focus on `after`, if
     *   there is no following tabbable element
     * @param respectDisableOverlayFocus if true, elements with the class disable-overlay-focus are skipped
     */
    focusFirstTabbableElementAfter(
        after: HTMLElement | null,
        wrapAround: boolean,
        respectDisableOverlayFocus = false
    ): HTMLElement | null {
        let trapOverlay: IStackEntry = null;

        // Find highest layer that's trapping focus
        for (let i = this.stack.length - 1; i >= 0; --i) {
            if (this.stack[i].focusTrap) {
                trapOverlay = this.stack[i];
                break;
            }
        }

        // Use its element as root of the search; or fallback to body
        const root = trapOverlay?.overlayRef.overlayElement ?? document.body;

        let target = getFirstTabbableElementAfter(
            root,
            after,
            { value: after === null },
            respectDisableOverlayFocus,
            this._interactivityChecker
        );

        if (!target) {
            if (wrapAround && after !== null) {
                // With wrap-around, just look for first element under the root
                target = getFirstTabbableElementAfter(
                    root,
                    null,
                    { value: true },
                    respectDisableOverlayFocus,
                    this._interactivityChecker
                );
            } else if (after?.focus) {
                // Without wrap-around, stay at the original element
                target = after;
            }
        }

        if (target) {
            target.focus();
        } else {
            this._blurFocused();
        }
        return target;
    }

    /**
     * Return api of the overlay owning the element, or null if none
     *
     * @param   element  HTML element to be found
     *
     * @return  api of the owner layer, or null
     */
    getOwningOverlay(element: HTMLElement): IOverlayResultApi | null {
        if (this.stack.length === 0) return null; // shortcut, there are no layers

        const container = closestElement(element, ".cdk-overlay-pane");
        if (!container) return null;
        return this.stack.find(entry => entry.overlayRef.overlayElement === container)?.api ?? null;
    }

    /**
     * Returns true, if the element lies in the top layer (or there is none). If the element
     * isn't attached to the current document, returns always false
     *
     * @param   element  HTML element to be found
     *
     * @return  True, if the element is in top layer
     */
    isInTopLayer(element: HTMLElement): boolean {
        const layer = this.getOwningOverlay(element);
        if (layer === null) {
            return this.stack.length === 0 && document.documentElement.contains(element);
        } else {
            return layer.isTop();
        }
    }

    /**
     * Returns true, if the element lies in top active layer (or there is none). An active layer
     * is one that isn't covered by any layer with trapFocus.
     * If the element isn't attached to the current document, returns always false.
     *
     * @param   element  HTML element to be found
     *
     * @return  True, if the element is in top active layer
     */
    isInActiveLayer(element: HTMLElement): boolean {
        const layer = this.getOwningOverlay(element);
        if (layer === null) {
            if (!document.documentElement.contains(element)) return false;
            const cover = this.stack.find(entry => entry.focusTrap);
            return cover === undefined;
        } else {
            return layer.isActive();
        }
    }

    // ---------------------------------------------------------------------------------------------
    private _getFocusedElement(): HTMLElement {
        try {
            const active = document.activeElement as HTMLElement;
            // fuck you, IE  (without test for BODY the whole browser would be minimized)
            if (active && active.nodeName !== "BODY") return active;
            // todo: why? (active.tagName == "INPUT" || active.tagName == "TEXTAREA") {
            return null;
        } catch (activeFailed) {
            return null;
        }
    }

    private _blurFocused(): void {
        const focused = this._getFocusedElement();
        if (focused && focused.blur) {
            focused.blur();
        }
    }
}

@Component({
    selector: "lg-wait-overlay-component",
    template: ""
})
export class WaitOverlayComponent {
    // empty
}
