import { IStringLookup } from "@logex/framework/types";

interface Separators {
    thousand: Separator;
    decimal: Separator;
}

interface Separator {
    symbol: string;
    removeRegex: RegExp;
}

interface Part {
    type: string;
    value: string;
}

const cache: IStringLookup<Separators> = {};

export function getNumberSeparators(locale: string): Separators {
    return cache[locale] || (cache[locale] = getNumberSeparatorsDo(locale));
}

function getNumberSeparatorsDo(locale: string): Separators {
    const val = 12345.6;
    const numberFormat = Intl.NumberFormat(locale);

    // even though typings see 'formatToParts' as method on 'NumberFormat'
    // build still fails saying the method doesn't exist so that's why 'any'
    // also IE and Edge don't have it so polyfilling for them
    if (typeof (numberFormat as any).formatToParts === "undefined") {
        return numberSeparatorsPolyfill(locale, val);
    }

    const parts: Part[] = (numberFormat as any).formatToParts(val);
    const groupSeperator = parts.find(x => x.type === "group");
    const decimalSeparator = parts.find(x => x.type === "decimal");
    return {
        thousand: separatorFactory(groupSeperator ? groupSeperator.value : ""),
        decimal: separatorFactory(decimalSeparator ? decimalSeparator.value : "")
    };
}

function numberSeparatorsPolyfill(locale: string, value: number): Separators {
    const testStr = Intl.NumberFormat(locale).format(value);
    const nonDigits = testStr.replace(/\d/g, "").split("");

    const decimalSeparator = nonDigits[nonDigits.length - 1];
    const thousandsSepartor = nonDigits.length === 1 ? "" : nonDigits[0];

    return {
        thousand: separatorFactory(thousandsSepartor),
        decimal: separatorFactory(decimalSeparator)
    };
}

function separatorFactory(symbol: string): Separator {
    return {
        symbol,
        // we could use non-g regexp for decimals since there will always be one
        // but we're reusing one regex multiple times so it would work just once in our scenario
        removeRegex: new RegExp(`[${symbol}]`, "g")
    };
}
