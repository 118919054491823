import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServerGatewayBase } from "@logex/framework/lg-application";
import { LG_LOCALIZATION_SETTINGS } from "@logex/framework/lg-localization";

import { LG_HELP_TOOLTIP_CONFIG, LgHelpTooltipItem, LgHelpTooltipsResponse } from "../types/types";

@Injectable({ providedIn: "root" })
export class LgHelpTooltipServiceGateway extends ServerGatewayBase {
    private _lgHelpTooltipConfig = inject(LG_HELP_TOOLTIP_CONFIG);
    private _localizationSettings = inject(LG_LOCALIZATION_SETTINGS);

    constructor() {
        super();
        const baseUrl = this._lgHelpTooltipConfig.baseUrl ?? "https://support.logex.com";
        this._setBaseUrl(baseUrl + "/wp-json/tooltip-api/v1/");
    }

    getValue(key: string, lang?: string): Observable<LgHelpTooltipItem> {
        return this._get("tooltip", {
            params: {
                topic: this._lgHelpTooltipConfig.appId,
                lang: lang ?? this._localizationSettings.locale,
                id: key
            }
        });
    }

    getValues(keys: string[], lang?: string): Observable<LgHelpTooltipsResponse> {
        return this._post("tooltips", {
            body: {
                topic: this._lgHelpTooltipConfig.appId,
                lang: lang ?? this._localizationSettings.locale,
                ids: keys
            }
        });
    }
}
