import { ElementRef } from "@angular/core";

/**
 * Determine, if the target element has specified class.
 * TODO: drop if we abandon IE9
 *
 * @param element
 * @param className
 */
export function elementHasClass(element: ElementRef | HTMLElement, className: string): boolean {
    if (!element) return false;

    let target: HTMLElement;
    if ("nativeElement" in element) {
        target = element.nativeElement;
    } else {
        target = element;
    }

    if (target.classList) {
        return target.classList.contains(className);
    } else {
        return new RegExp("(^| )" + className + "( |$)", "gi").test(target.className);
    }
}
